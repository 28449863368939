import { ModalType } from './Header';
import MenuPopover from './MenuPopover';
import { PigogoButton } from 'components';
import { Box, Divider, Fade, IconButton, Paper, Portal, Skeleton, Typography } from '@mui/material';
import CustomContainer from '../../CustomContainer';
import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { usePromosQuery } from '../../../redux/api/queries/promosQueries';
import { useSavingsQuery } from '../../../redux/api/queries/savingsQueries';
import { useAllCategoriesQuery } from '../../../redux/api/queries/categoriesQuery';
import { ReactComponent as Chrome } from '../../../assets/svgs/searchApp/chrome.svg';
import { ReactComponent as Firefox } from '../../../assets/svgs/searchApp/firefox.svg';
import { isPlatform, useIonRouter } from '@ionic/react';
import { useGetAllBasicContentQuery } from '../../../redux/api/queries/basicContentQuery';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setClosedAlert, setFirstLogin, setSeenAlert } from '../../../redux/slices/authenticationSlice';
import closeWhite from '../../../assets/svgs/closeWhite.svg';
import AlertModal from '../Modals/AlertModal';
import detectBrowser from '../../../utils/browserDetection';
import { ClickAwayListener, Popper } from '@mui/base';
import { Link, useHistory } from 'react-router-dom';
import { useGetUserDataQuery } from '../../../redux/api/queries/userQueries';
import moment from 'moment-timezone';
import { useUpdateUserMutation } from '../../../redux/api/mutations/userMutations';

type Props = {
  container?: any;
  handlePigogoAlert: () => void;
  handleLetsStartModal: () => void;
  manageModals: (modalType?: ModalType) => void;
  setResetFilters?: (value: boolean) => void;
  setStallAdBlocker?: (value: boolean) => void;
  stallAdBlocker?: boolean;
  fromHomePage?: boolean;
  setAdBlockers?: (value: boolean) => void;
};
const SecondBar: FC<Props> = ({
  container,
  setResetFilters,
  handleLetsStartModal,
  manageModals,
  setStallAdBlocker,
  stallAdBlocker,
  fromHomePage,
  setAdBlockers,
}) => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();

  const history = useHistory();

  const desktop = isPlatform('desktop');
  const browser = detectBrowser();

  const [tooltip, setTooltip] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);
  const [shopsAnchorEl, setShopsAnchorEl] = useState<HTMLElement | null>(null);
  const [offersAnchorEl, setOffersAnchorEl] = useState<HTMLElement | null>(null);

  const { data: userData, isFetching: isUserLoading, isSuccess: isUserSuccess } = useGetUserDataQuery();
  const [updateUser, { isError: updateError, reset: resetUser }] = useUpdateUserMutation();

  const { data: promos, isLoading: promosLoading } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const { data: savings, isLoading: savingsLoading } = useSavingsQuery({ limit: 4, no_promo: true });
  const { data: allContent, isLoading: contentLoading } = useGetAllBasicContentQuery();
  const { data: allCategories, isLoading: categoriesLoading } = useAllCategoriesQuery(
    { filter: 'parents' },
    { refetchOnMountOrArgChange: false },
  );

  const isFirstLogin = useAppSelector((state) => state.authentication.data.firstLogin);
  const hasSeenAlert = useAppSelector((state) => state.authentication.data.seenAlert);
  const hasClosedAlert = useAppSelector((state) => state.authentication.data.closedAlert);
  const [isFocused, setIsFocused] = useState<boolean>(true);

  const anchorElRef: any = useRef();
  const anchorButtonRef: any = useRef();

  const basicContent = () => {
    if (contentLoading && allContent !== undefined) {
      return (
        <Skeleton
          variant="rectangular"
          animation={'wave'}
          style={{
            width: '80px',
            height: '31px',
            borderRadius: 8,
            background: 'rgb(255 255 255 / 46%)',
          }}
        />
      );
    } else if (allContent !== undefined && allContent.data && !contentLoading) {
      return allContent.data.map((item, index) => (
        <React.Fragment key={index}>
          <Link className="btnLink btnLink--icon" to={`/pages/${item.slug}`}>
            {item.menuBarName}
          </Link>
        </React.Fragment>
      ));
    }
  };

  const handleLetsStart = () => {
    setTooltip(false);
    handleLetsStartModal();
  };

  useIonViewDidEnter(() => {
    setTooltip(false);
    setIsFocused(true);
  });

  useEffect(() => {
    if (hasClosedAlert && isFirstLogin) {
      setTimeout(() => {
        dispatch(setFirstLogin({ isFirst: false }));
        setTooltip(true);
      }, 7000);
    }
  }, [hasClosedAlert]);

  useEffect(() => {
    if (isFocused && !isUserLoading && userData) {
      if (!hasSeenAlert) {
        if (!userData.has_used_extension) {
          if (
            Math.abs(moment(userData.shown_extension_modal_date).diff(moment(new Date()), 'months', true)) >= 1 ||
            !userData.shown_extension_modal_date
          ) {
            setAlert(true);
            dispatch(setSeenAlert({ seenAlert: true }));
            dispatch(setClosedAlert({ closedAlert: false }));

            updateUser({
              shown_extension_modal_date: moment().tz('Europe/Athens').toISOString(),
            });
          }
        } else {
          dispatch(setSeenAlert({ seenAlert: true }));
        }
      }
    }
  }, [isUserLoading]);

  useEffect(() => {
    if (stallAdBlocker && !alert && !fromHomePage) {
      //User has closed Alert Modal
      setTimeout(() => {
        setStallAdBlocker && setStallAdBlocker(false);
      }, 60000);
      updateUser({
        shown_adblockers_modal_date: moment().tz('Europe/Athens').toISOString(),
      });
    }
  }, [stallAdBlocker, alert]);

  useIonViewWillLeave(() => {
    setIsFocused(false);
  });

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 0,
        position: 'relative',
        borderTop: '1px solid #EAECEE',
        borderBottom: '1px solid #EAECEE',
        zIndex: (theme) => theme.zIndex.modal + 1,
      }}
      onClick={() => {
        setShopsAnchorEl(null);
        setOffersAnchorEl(null);
      }}
    >
      <ClickAwayListener
        onClickAway={() => {
          !alert && setTooltip(false);
        }}
      >
        <Popper
          anchorEl={anchorButtonRef.current}
          keepMounted
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [-50, 30],
              },
            },
            {
              name: 'computeStyles',
              options: {
                adaptive: false,
              },
            },
          ]}
          placement={'top-end'}
          open={tooltip && isFocused}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Box
                sx={{
                  zIndex: 1,
                  borderRadius: '16px',
                  width: '343px',
                  padding: '24px',
                  backgroundColor: '#1D2532',
                  margin: 0,
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center" gap={'20px'} width={'271px'}>
                  <IconButton
                    sx={{ position: 'absolute', right: 16, top: 24, padding: 0 }}
                    disableRipple
                    onClick={() => {
                      setTooltip(false);
                    }}
                  >
                    <img src={closeWhite} />
                  </IconButton>
                  <Typography variant="body2MediumR" color={'white'}>
                    Δες πόσο εύκολα μπορείς να κερδίζεις επιστροφή χρημάτων
                  </Typography>
                  <PigogoButton fullWidth variation="white" text="Ας ξεκινήσουμε" onClick={handleLetsStart} />
                </Box>
              </Box>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
      <CustomContainer display="flex" justifyContent="space-between" alignItems="center" height="56px">
        <Box
          display="flex"
          alignItems="center"
          gap={4}
          ml={-2}
          sx={{ color: '#313D53' }}
          onClick={(e: any) => e.stopPropagation()}
        >
          <Box ref={anchorElRef} display="flex" alignItems="center">
            <MenuPopover
              promos={promos}
              title="Καταστήματα"
              anchorEl={shopsAnchorEl}
              container={container}
              allCategories={allCategories}
              loading={categoriesLoading || promosLoading}
              setResetFilters={setResetFilters}
              setAnchorEl={(value) => {
                if (shopsAnchorEl) {
                  setShopsAnchorEl(null);
                } else {
                  setShopsAnchorEl(value);
                  manageModals();
                }
                setOffersAnchorEl(null);
              }}
            />
          </Box>

          <MenuPopover
            savings={savings}
            refTo={anchorElRef}
            title="Hot προσφορές"
            loading={savingsLoading}
            anchorEl={offersAnchorEl}
            container={container}
            setAnchorEl={(value) => {
              if (offersAnchorEl) {
                setOffersAnchorEl(null);
              } else {
                setOffersAnchorEl(value);
                manageModals();
              }
              setShopsAnchorEl(null);
            }}
          />
        </Box>

        <Box display="flex" gap={4} alignItems="center">
          <Box display="flex" gap={1}>
            {contentLoading ? (
              <Skeleton
                variant="rectangular"
                animation="wave"
                style={{
                  width: '113px',
                  height: '31px',
                  borderRadius: 8,
                  background: 'rgb(255 255 255 / 46%)',
                }}
              />
            ) : (
              <Link className="btnLink btnLink--icon" to={'/how-it-works'}>
                <span>Πώς λειτουργεί</span>
              </Link>
            )}

            {allContent !== undefined && allContent?.data.length != 0 && !contentLoading && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}
            {basicContent()}
          </Box>
          <Link className="btnLink btnLink--icon" to={'/alert'}>
            {browser == 'Firefox' ? <Firefox /> : <Chrome />}
            <span>Pigogo ALERT!</span>
            <Box ref={anchorButtonRef} sx={{ position: 'fixed' }}></Box>
          </Link>
        </Box>
      </CustomContainer>
      <AlertModal
        open={
          (desktop && alert && !fromHomePage) ||
          (desktop && alert && !!fromHomePage && history.location.pathname == '/')
        }
        handleClose={() => {
          dispatch(setClosedAlert({ closedAlert: true }));
          setAlert(false);

          setStallAdBlocker && setStallAdBlocker(true);
          dispatch(setClosedAlert({ closedAlert: true }));

          updateUser({
            shown_extension_modal_date: moment().tz('Europe/Athens').toISOString(),
          });
        }}
        browser={browser}
      />
      {(shopsAnchorEl || offersAnchorEl) && (
        <Portal>
          <Box
            position={'fixed'}
            top={0}
            height={'80px'}
            right={0}
            left={0}
            zIndex={2000}
            onClick={() => {
              setShopsAnchorEl(null);
              setOffersAnchorEl(null);
            }}
          ></Box>
        </Portal>
      )}
    </Paper>
  );
};

export default SecondBar;
