import {
  Accordion,
  AccordionDetails,
  Box,
  Divider,
  IconButton,
  Skeleton,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { FC, SyntheticEvent } from 'react';
import { PigogoCheckboxCircle, theme } from 'components';
import { ReactComponent as Bank } from '../../../assets/svgs/bank.svg';
import { ReactComponent as Viva } from '../../../assets/svgs/viva.svg';
import { ReactComponent as Edit } from '../../../assets/svgs/editCard.svg';
import { ReactComponent as Plus } from '../../../assets/svgs/plus_circle.svg';
import { ReactComponent as Minus } from '../../../assets/svgs/minus_circle.svg';
import { ReactComponent as Unchecked } from '../../../assets/svgs/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svgs/check_pink.svg';

const StyledAccordion = styled(Accordion)(() => ({
  '& .MuiButtonBase-root': {
    alignItems: 'flex-start',
  },
  '&.MuiAccordion-root': {
    border: '2px solid #EAECEE',
    borderRadius: '24px',

    minHeight: 0,
    margin: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: '8px 32px 24px 32px',
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));

type AccordionCardProps = {
  hasData: boolean;
  bank: boolean;
  minimumAmount: number;
  defaultMethod: boolean;
  loading: boolean;
  paymentDetails?: {
    iban?: string;
    mobile?: string;
    beneficiary?: string;
    threshold?: string;
    defaultMethod?: boolean;
  };
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  setCheck: (value: boolean) => void;
  children: React.ReactNode;
};

const AccordionCard: FC<AccordionCardProps> = ({
  paymentDetails,
  loading,
  hasData,
  bank,
  minimumAmount,
  defaultMethod,
  isExpanded,
  setIsExpanded,
  setCheck,
  children,
}) => {
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event: SyntheticEvent, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  const expandIcons = () => {
    if (loading) {
      return <Skeleton variant="circular" width={24} height={24} />;
    }
    if (hasData) {
      return isExpanded ? (
        <IconButton onClick={(e) => handleChange(e, false)} disableRipple>
          <Minus />
        </IconButton>
      ) : (
        <IconButton disableRipple onClick={(e) => handleChange(e, true)}>
          <Edit />
        </IconButton>
      );
    }
    return isExpanded ? (
      <IconButton sx={{ padding: 0 }} onClick={(e) => handleChange(e, false)} disableRipple>
        <Minus />
      </IconButton>
    ) : (
      <IconButton disableRipple sx={{ padding: 0 }} onClick={(e) => handleChange(e, true)}>
        <Plus />
      </IconButton>
    );
  };

  const renderDefaultMethod = () => {
    if (hasData) {
      return (
        <Box
          display={'flex'}
          alignItems={smDown && !defaultMethod ? 'flex-start' : 'center'}
          gap={2}
          sx={{ position: 'relative' }}
        >
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0,
            }}
          >
            <PigogoCheckboxCircle
              inputProps={{
                checked: true,
                disableRipple: true,
                color: 'secondary',
                icon: <Unchecked />,
                checkedIcon: <CheckedIcon />,
                readOnly: defaultMethod,
                disabled: defaultMethod,
                sx: { '&': { width: '100%', height: '100%', alignItems: 'center !important', position: 'static' } },
              }}
              checked={defaultMethod}
              setValue={() => setCheck(!defaultMethod)}
            />
          </Box>

          <Typography
            variant={'mediumM'}
            color={'#313D53'}
            component="p"
            style={{ color: defaultMethod ? '#E9688A' : '#313D53' }}
          >
            {defaultMethod ? 'Επιλεγμένος τρόπος πληρωμής' : 'Ορισμός ως επιλεγμένου τρόπου πληρωμής'}
          </Typography>
        </Box>
      );
    }
  };

  const renderBank = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={5}>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography variant={'caption'} color={'#313D53'}>
              IBAN
            </Typography>
            {paymentDetails?.iban && paymentDetails?.beneficiary && paymentDetails?.threshold ? (
              <Typography variant={'body2'} color={'#313D53'}>
                {paymentDetails?.iban}
              </Typography>
            ) : (
              <Skeleton variant="text" width={300} height={24} />
            )}
          </Box>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography variant={'caption'} color={'#313D53'}>
              Δικαιούχος
            </Typography>
            {paymentDetails?.iban && paymentDetails?.beneficiary && paymentDetails?.threshold ? (
              <Typography variant={'body2'} color={'#313D53'}>
                {paymentDetails?.beneficiary}
              </Typography>
            ) : (
              <Skeleton variant="text" width={263} height={24} />
            )}
          </Box>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography variant={'caption'} color={'#313D53'}>
              Ελάχιστο ποσό πληρωμής:
            </Typography>
            {paymentDetails?.iban && paymentDetails?.beneficiary && paymentDetails?.threshold ? (
              <Typography variant={'body2'} color={'#313D53'}>
                {paymentDetails?.threshold}
              </Typography>
            ) : (
              <Skeleton variant="text" width={25} height={24} />
            )}
          </Box>
        </Box>
        {renderDefaultMethod()}
      </Box>
    );
  };

  const renderViva = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} gap={5}>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography variant={'caption'} color={'#313D53'}>
              Κινητό
            </Typography>
            {paymentDetails?.mobile && paymentDetails?.beneficiary && paymentDetails?.threshold ? (
              <Typography variant={'body2'} color={'#313D53'}>{`+30 ${paymentDetails?.mobile}`}</Typography>
            ) : (
              <Skeleton variant="text" width={300} height={24} />
            )}
          </Box>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography variant={'caption'} color={'#313D53'}>
              Δικαιούχος
            </Typography>
            {paymentDetails?.mobile && paymentDetails?.beneficiary && paymentDetails?.threshold ? (
              <Typography variant={'body2'} color={'#313D53'}>
                {paymentDetails?.beneficiary}
              </Typography>
            ) : (
              <Skeleton variant="text" width={274} height={24} />
            )}
          </Box>
          <Box display={'flex'} gap={2} alignItems={'center'}>
            <Typography variant={'caption'} color={'#313D53'}>
              Ελάχιστο ποσό πληρωμής:
            </Typography>
            {paymentDetails?.mobile && paymentDetails?.beneficiary && paymentDetails?.threshold ? (
              <Typography variant={'body2'} color={'#313D53'}>
                {paymentDetails?.threshold}
              </Typography>
            ) : (
              <Skeleton variant="text" width={25} height={24} />
            )}
          </Box>
        </Box>
        {renderDefaultMethod()}
      </Box>
    );
  };

  const renderContent = () => {
    if (isExpanded || !hasData) {
      return;
    }
    if (bank) {
      return <Box padding={smDown ? '16px' : '32px'}>{renderBank()}</Box>;
    }
    return <Box padding={smDown ? '16px' : '32px'}>{renderViva()}</Box>;
  };

  const renderHeaderSection = () => {
    if ((isExpanded || hasData) && !loading) {
      return (
        <Box display="flex" flexDirection="column">
          <Box p={smDown ? '16px' : '24px 32px'} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" gap={2}>
              {bank ? (
                <Bank style={{ width: '64px', height: '64px' }} />
              ) : (
                <Viva style={{ width: '64px', height: '64px' }} />
              )}
              <Box display="flex" gap={!smDown ? 2 : 1} flexDirection={smDown ? 'column' : 'row'}>
                <Typography variant={'subtitle1'} component="h3" color={'#313D53'}>
                  {bank ? 'Κατάθεση σε τράπεζα' : 'Viva Wallet'}
                </Typography>
                <Typography style={{ color: '#6F7787' }} variant={'subtitle1SmallR'} component="p">
                  {`Ελάχιστο ποσό: ${minimumAmount}€`}
                </Typography>
              </Box>
            </Box>
            {expandIcons()}
          </Box>
          <Divider />
          {renderContent()}
        </Box>
      );
    } else {
      return (
        <Box p={smDown ? '16px' : '24px 32px'} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={4}>
            <Box>
              {loading ? (
                <Skeleton variant="rectangular" width="120px" height="120px" sx={{ borderRadius: '20px' }} />
              ) : bank ? (
                <Bank style={{ width: smDown ? '64px' : '', height: smDown ? '64px' : '' }} />
              ) : (
                <Viva style={{ width: smDown ? '64px' : '', height: smDown ? '64px' : '' }} />
              )}
            </Box>
            <Box display="flex" gap={!smDown && hasData ? 2 : 1} flexDirection={smDown || !hasData ? 'column' : 'row'}>
              <Typography variant={!smDown && !hasData ? 'largeMedium' : 'subtitle1'} color={'#313D53'}>
                {bank ? 'Κατάθεση σε τράπεζα' : 'Viva Wallet'}
              </Typography>
              <Typography
                style={{ color: '#6F7787' }}
                variant={!smDown && !hasData ? 'body2MediumR' : 'subtitle1SmallR'}
                component="p"
              >
                {`Ελάχιστο ποσό: ${minimumAmount}€`}
              </Typography>
            </Box>
          </Box>
          {expandIcons()}
        </Box>
      );
    }
  };

  return (
    <StyledAccordion disableGutters elevation={0} expanded={isExpanded} onChange={handleChange}>
      <Box>{renderHeaderSection()}</Box>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default AccordionCard;
