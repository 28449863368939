import React, { FC } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { PigogoLink, PigogoOfferCard, PigogoSingleCouponCard, theme } from 'components';
import { useIonRouter } from '@ionic/react';
import { GenericRequest } from '../../../models/GenericRequest';
import { Savings } from '../../../models/Savings';
import { setSnackBar } from '../../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import { Link } from 'react-router-dom';

interface Props {
  savings?: GenericRequest<Savings[]>;
  handleClose: () => void;
}

const OffersHeaderContent: FC<Props> = ({ handleClose, savings }) => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const accessToken = useAppSelector((state) => state.authentication.data.accessToken);
  const sessionLoggedIn = useAppSelector((state) => state.authentication.data.sessionLoggedIn);

  const handleCardClick = (slugName: string, savingId: string) => {
    handleClose();
    router.push(`/${slugName}?offer=${savingId}`);
  };

  const handleAllOffersClick = () => {
    handleClose();
  };

  const xlUp = useMediaQuery(theme.breakpoints.up('extraLg'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const getWidth = () => {
    if (xlUp) {
      return 'calc(100% + 24px)';
    }
    if (mdUp) {
      return 'calc(100% + 16px)';
    }
    return 'calc(100% + 8px)';
  };

  const onClickCopy = () => {
    const params = { value: true, msg: 'Αντιγράφηκε στο πρόχειρο', severity: 'infoBlue' };
    dispatch(setSnackBar(params));
  };

  return (
    <Box sx={{ maxHeight: '509px' }}>
      <Box display={'flex'} flexDirection={'column'} gap={4} height={'auto'}>
        <Box display={'flex'} gap={2}>
          {savings &&
            savings?.data.slice(0, 4).map((item, index: number) =>
              item.is_offer ? (
                <Box key={index} sx={{ width: xlUp ? 'calc(25% - 12px)' : 'calc(25% - 6px)' }}>
                  <Link className="storeCard" to={`/${item.shop.slug.name}?offer=${item.id}`}>
                    <PigogoOfferCard
                      name={item.shop.name}
                      logo={item.shop.image}
                      subTitle={item.description}
                      date={item.end_date ? item.end_date : ''}
                      onClick={handleClose}
                      discount={item.shop.cashback !== null ? `${item.shop.cashback}${item.shop.currency}` : undefined}
                      multiCashback={item.shop.cashback_categories_count >= 2}
                      fullwidth={true}
                      deleteDisc={
                        item.shop.strikedCashback !== null
                          ? `${item.shop.strikedCashback}${item.shop.strikedCurrency}`
                          : undefined
                      }
                    />
                  </Link>
                </Box>
              ) : (
                <Box key={index} sx={{ width: xlUp ? 'calc(25% - 12px)' : 'calc(25% - 6px)' }}>
                  <Box sx={{}}>
                    <Link className="storeCard" to={`/${item.shop.slug.name}?offer=${item.id}`}>
                      <PigogoSingleCouponCard
                        logout={!(accessToken || sessionLoggedIn)}
                        name={item.shop.name}
                        logo={item.shop.image}
                        code={item.code || ''}
                        subTitle={item.description}
                        date={item.end_date ? item.end_date : ''}
                        onClick={handleClose}
                        onClickCopy={onClickCopy}
                        discount={
                          item.shop.cashback !== null ? `${item.shop.cashback}${item.shop.currency}` : undefined
                        }
                        cashbackCount={item.shop.cashback_categories_count}
                        deleteDisc={
                          item.shop.strikedCashback !== null
                            ? `${item.shop.strikedCashback}${item.shop.strikedCurrency}`
                            : undefined
                        }
                        storeSlug={item.shop.slug.name}
                        id={item.id}
                      />
                    </Link>
                  </Box>
                </Box>
              ),
            )}
        </Box>
        <Box>
          <Link
            className="btnLink btnLink--underline btnLink--blue"
            to={`/offers`}
            onClick={() => handleAllOffersClick()}
          >
            Όλες οι προσφορές
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default OffersHeaderContent;
