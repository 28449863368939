import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import { theme } from 'components';
import { useIonRouter } from '@ionic/react';
import useMediaQuery from '@mui/material/useMediaQuery';
import TopBar from './TopBar';
import SecondBar from './SecondBar';
import AlertModal from '../Modals/AlertModal';
import WalkthroughModal from '../Modals/WalkthroughModal';
import RegisterModal from '../Modals/RegisterModal';
import ForgotModal from '../Modals/ForgotModal';
import LoginModal from '../Modals/LoginModal';
import SuccessPasswordModal from '../Modals/SuccessPasswordModal';
import RedirectModal from '../Modals/RedirectModal';
import { useGetUserDataQuery } from '../../../redux/api/queries/userQueries';

type HeaderProps = {
  contentRef?: any;
  setResetFilters?: (value: boolean) => void;
  setSearchKeyword?: (value: string) => void;
  searchKeyword?: string;
  setStallAdBlocker?: (value: boolean) => void;
  stallAdBlocker?: boolean;
  fromHomePage?: boolean;
  setAdBlockers?: (value: boolean) => void;
};

const Header: FC<HeaderProps> = ({
  contentRef,
  setResetFilters,
  setSearchKeyword,
  searchKeyword,
  setStallAdBlocker,
  stallAdBlocker,
  fromHomePage,
}) => {
  const small = useMediaQuery(theme.breakpoints.down('md'));

  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openLogin, setOpenLogin] = useState<boolean>(false);
  const [openForgot, setOpenForgot] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [openRegister, setOpenRegister] = useState<boolean>(false);
  const [openWalkthrough, setOpenWalkthrough] = useState<boolean>(false);

  const { data: userData } = useGetUserDataQuery();
  const [redirectModal, setRedirectModal] = useState<boolean>(false);
  const [shopName, setShopName] = useState<string>('');
  const [shopImage, setShopImage] = useState(undefined);

  const openPigogoAlertModal = () => {
    setOpenAlert(true);
  };

  const openWalkthroughModal = () => {
    setOpenWalkthrough(true);
  };

  const manageModals = (active?: ModalType) => {
    switch (active) {
      case 'login':
        setOpenLogin(true);
        setOpenAlert(false);
        setOpenRegister(false);
        setOpenForgot(false);
        setOpenSuccess(false);
        setOpenWalkthrough(false);
        break;
      case 'register':
        setOpenRegister(true);
        setOpenAlert(false);
        setOpenLogin(false);
        setOpenForgot(false);
        setOpenSuccess(false);
        setOpenWalkthrough(false);
        break;
      case 'forget':
        setOpenForgot(true);
        setOpenAlert(false);
        setOpenLogin(false);
        setOpenRegister(false);
        setOpenSuccess(false);
        setOpenWalkthrough(false);
        break;
      case 'success':
        setOpenSuccess(true);
        setOpenAlert(false);
        setOpenLogin(false);
        setOpenRegister(false);
        setOpenForgot(false);
        setOpenWalkthrough(false);
        break;
      case 'walkthrough':
        setOpenWalkthrough(true);
        setOpenAlert(false);
        setOpenLogin(false);
        setOpenRegister(false);
        setOpenForgot(false);
        setOpenSuccess(false);
        break;
      case 'alert':
        setOpenAlert(true);
        setOpenLogin(false);
        setOpenRegister(false);
        setOpenForgot(false);
        setOpenSuccess(false);
        setOpenWalkthrough(false);
        break;
      default:
        setOpenLogin(false);
        setOpenRegister(false);
        setOpenForgot(false);
        setOpenSuccess(false);
        setOpenWalkthrough(false);
        setOpenAlert(false);
    }
  };

  const openForgotModal = () => {
    setOpenLogin(false);
    setOpenForgot(true);
  };

  return (
    <Box position={'relative'}>
      <TopBar
        container={contentRef}
        manageModals={manageModals}
        setSearchKeyword={setSearchKeyword}
        searchKeyword={searchKeyword}
        setRedirectModal={setRedirectModal}
        setShopName={setShopName}
        setShopImage={setShopImage}
      />
      {!small && (
        <SecondBar
          container={contentRef}
          manageModals={manageModals}
          handlePigogoAlert={openPigogoAlertModal}
          handleLetsStartModal={openWalkthroughModal}
          setResetFilters={setResetFilters}
          setStallAdBlocker={setStallAdBlocker}
          stallAdBlocker={stallAdBlocker}
          fromHomePage={fromHomePage}
        />
      )}

      <AlertModal
        open={openAlert && !stallAdBlocker}
        handleClose={() => {
          setOpenAlert(false);
          setStallAdBlocker && setStallAdBlocker(true);
        }}
      />
      <ForgotModal isOpen={openForgot} setOpen={() => setOpenForgot(false)} success={() => setOpenSuccess(true)} />
      <RegisterModal
        isOpen={openRegister}
        setOpen={setOpenRegister}
        userDismiss={() => {
          setOpenLogin(false);
          setOpenRegister(false);
        }}
        setLoginModal={(value: boolean) => {
          setOpenLogin(value);
        }}
      />
      <LoginModal
        setLoginModal={(value: boolean) => {
          setOpenLogin(value);
        }}
        isOpen={openLogin}
        dismiss={() => setOpenLogin(false)}
        openForgotModal={openForgotModal}
        openRegisterModal={() => setOpenRegister(true)}
      />
      <SuccessPasswordModal
        open={openSuccess}
        title={'Ευχαριστούμε πολύ!'}
        handleClose={() => setOpenSuccess(false)}
        subTitle={'Το link δημιουργίας νέου κωδικού έχει σταλεί στο email σου.'}
      />
      <WalkthroughModal open={openWalkthrough} handleClose={() => setOpenWalkthrough(false)} />
      <RedirectModal
        extensionPigogo={userData ? userData.has_used_extension : false}
        open={redirectModal}
        handleClose={() => setRedirectModal(false)}
        icon={shopImage}
        shop={shopName}
        subTitle="Προχώρησε κατευθείαν στην αγορά σου για να κερδίσεις επιστροφή χρημάτων!"
      />
    </Box>
  );
};

export default Header;
export type ModalType = 'login' | 'register' | 'forget' | 'success' | 'walkthrough' | 'alert';
