import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { PigogoCheckbox, PigogoTextArea, PigogoTextField, RichTooltip, theme } from 'components';
import DatePicker from '../../DatePicker';
import TimePicker from '../../TimePicker';
import { useShopQuery } from '../../../redux/api/queries/shopQuery';
import { CreateClaimStepThree } from '../../../models/CreateClaimStepThree';
import { useAppDispatch } from '../../../redux/hooks';
import { setSnackBar } from '../../../redux/slices/layoutSlice';
import dayjs from 'dayjs';
import { ReactComponent as Infolight } from '../../../assets/svgs/info_light_color.svg';

const BpIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  backgroundColor: '#ffffff',
  boxShadow: 'inset 0 0 0 1px #838B98, inset 0 -1px 0 #838B98',

  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  boxShadow: 'inset 0 0 0 1px #E9688A, inset 0 -1px 0 #E9688A',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
}));

const paymentMethodTypes = [
  { value: 'Αντικαταβολή', label: 'Αντικαταβολή' },
  { value: 'Πιστωτική/Χρεωστική Κάρτα', label: 'Πιστωτική / Χρεωστική κάρτα' },
  { value: 'Paypal', label: 'PayPal' },
  { value: 'Τραπεζική Κατάθεση', label: 'Τραπεζική Κατάθεση' },
  { value: 'other', label: 'Άλλο' },
];

interface Props {
  formErrors: any;
  shopSlugName: string;
  otherPaymentType: string;
  stepThreeState: CreateClaimStepThree;
  setFormErrors: (arg0: any) => void;
  setOtherPaymentType: (otherPaymentType: string) => void;
  setStepThreeState: (stepThreeState: CreateClaimStepThree) => void;
  shopDisplayConfirmation: boolean;
}

const StepThreeContent: FC<Props> = ({
  stepThreeState,
  setStepThreeState,
  shopSlugName,
  otherPaymentType,
  setOtherPaymentType,
  setFormErrors,
  formErrors,
  shopDisplayConfirmation,
}) => {
  const dispatch = useAppDispatch();

  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const xxsDown = useMediaQuery(theme.breakpoints.down('xxs'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState<boolean>(false);

  const { data: shopInfo, isLoading: shopInfoLoading, isError, error } = useShopQuery({ slugName: shopSlugName });

  const MouseOver = () => {
    setOpen((previousOpen) => !previousOpen);
  };

  const MouseOut = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (shopInfo && shopInfo.cashback_categories) {
      if (shopInfo.cashback_categories.length === 1) {
        setStepThreeState({
          ...stepThreeState,
          cashback_category_id: [shopInfo?.cashback_categories[0].id.toString()],
        });
      }
    }
  }, [setStepThreeState, shopInfo, stepThreeState]);

  useEffect(() => {
    if (isError && shopSlugName !== 'tell-a-friend') {
      dispatch(
        setSnackBar({
          msg: 'Δεν μπορέσαμε να βρούμε cashback κατηγορίες για το κατάστημα!',
          severity: 'error',
          value: true,
        }),
      );
    } else {
      dispatch(setSnackBar({ msg: '', severity: '', value: false }));
    }
  }, [isError, error]);

  const handleDateChange = (newValue: Date | null) => {
    setFormErrors({ ...formErrors, confirmation_date: false });
    if (stepThreeState['confirmation_date'] !== newValue) {
      setStepThreeState({ ...stepThreeState, confirmation_date: newValue });
    }
  };

  const handleTimeChange = (newValue: Date | null) => {
    setFormErrors({ ...formErrors, confirmation_time: false });
    if (stepThreeState['confirmation_time'] !== newValue) {
      setStepThreeState({ ...stepThreeState, confirmation_time: newValue });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrors({ ...formErrors, payment_type: false });
    setStepThreeState({ ...stepThreeState, payment_type: event.target.value });
  };

  const handlePayoutRemove = (category: any) => {
    const index = stepThreeState.cashback_category_id.indexOf(category.id.toString());
    stepThreeState.cashback_category_id.splice(index, 1);

    return stepThreeState.cashback_category_id;
  };

  const handlePayoutAppend = (category: any) => {
    stepThreeState.cashback_category_id.push(category.id.toString());

    return stepThreeState.cashback_category_id;
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={4} mt={-4}>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        {shopInfo && shopInfo?.cashback_categories?.length >= 2 && (
          <>
            <Box display={'flex'} flexDirection={'column'} gap={2} pt={3}>
              <Typography variant="mediumM" color={'#1D2532'} component="h4">
                Επίλεξε τις κατηγορίες που ανήκουν τα προϊόντα που αγόρασες
              </Typography>
              <Box display="flex" flexDirection="column" gap={2}>
                {shopInfoLoading && [1, 1, 1, 1].map((item, index) => <Skeleton height="50px" key={index} />)}
                {!shopInfoLoading &&
                  shopInfo &&
                  shopInfo?.cashback_categories?.length >= 2 &&
                  shopInfo?.cashback_categories?.map((category, index) => (
                    <Box key={index} p={'6px 8px'} sx={{ border: '1px solid #D6D8DD', borderRadius: '8px' }}>
                      <FormControlLabel
                        sx={{
                          margin: 0,
                          '& .MuiCheckbox-root': {
                            width: '24px',
                            height: '24px',
                            marginRight: 0,
                          },
                          '& .MuiCheckbox-root.Mui-checked': {
                            outlineOffset: '-4px',
                            outlineWidth: '4px',
                          },
                        }}
                        control={
                          <PigogoCheckbox
                            checked={stepThreeState.cashback_category_id.includes(category.id.toString())}
                            setValue={() => {
                              setFormErrors({ ...formErrors, cashback_category: false });
                              setStepThreeState({
                                ...stepThreeState,
                                cashback_category_id: stepThreeState.cashback_category_id.includes(
                                  category.id.toString(),
                                )
                                  ? handlePayoutRemove(category)
                                  : handlePayoutAppend(category),
                              });
                            }}
                            inputProps={{ color: 'secondary', sx: { padding: 0 } }}
                          />
                        }
                        label={
                          <Typography variant="subtitle1SmallR" pl={3} color={'#313D53'}>
                            {category.description}
                          </Typography>
                        }
                      />
                    </Box>
                  ))}
                {formErrors.cashback_category && (
                  <Typography variant={'caption'} color={'#F75676'} pl={3} component="p">
                    Το πεδίο είναι υποχρεωτικό.
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <PigogoTextField
          label="Ποσό αγοράς (€)"
          sx={{ width: '316px' }}
          error={formErrors.order_amount}
          value={
            stepThreeState.order_amount === ''
              ? ''
              : /^,{1,1}(\d*)$/.test(stepThreeState.order_amount.replace(/[^0-9,]/g, ''))
              ? stepThreeState.order_amount.replace(/[^0-9,]/g, '').replace(/^,{1,1}(\d*)$/, '0,$1')
              : stepThreeState.order_amount.replace(/[^0-9,]/g, '').replace(/^(\d+,{0,1}\d{0,2})[0-9,]*$/, '$1')
          }
          helperText={
            formErrors.order_amount
              ? 'Το πεδίο είναι υποχρεωτικό.'
              : 'Συμπλήρωσε την καθαρή αξία της αγοράς, χωρίς ΦΠΑ και άλλα έξοδα όπως αποστολής, αντικαταβολής, συσκευασίας κλπ.'
          }
          setValue={(value) => setStepThreeState({ ...stepThreeState, order_amount: value })}
          onFocus={() => {
            setFormErrors({ ...formErrors, order_amount: false });
          }}
          numeric={true}
        />
        <PigogoTextField
          id={'field1'}
          sx={{ width: '316px' }}
          error={formErrors.order_number}
          value={stepThreeState.order_number}
          label="Κωδικός/αριθμός παραγγελίας"
          helperText={formErrors.order_number && 'Το πεδίο είναι υποχρεωτικό.'}
          setValue={(value) => setStepThreeState({ ...stepThreeState, order_number: value })}
          onFocus={() => {
            setFormErrors({ ...formErrors, order_number: false });
          }}
        />
      </Box>
      <Divider />
      <Box>
        <Typography variant="mediumM" color={'#1D2532'} component="h4">
          Τρόπος πληρωμής
        </Typography>
        <Box pt={3} display={'flex'} flexDirection={'column'} gap={3}>
          <FormControl>
            <RadioGroup
              row={!xsOnly}
              onChange={handleChange}
              defaultValue="Αντικαταβολή"
              value={stepThreeState.payment_type}
              aria-labelledby="demo-radio-buttons-group-label"
              sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', flexDirection: 'column' }}
            >
              {paymentMethodTypes.map((type, index) => (
                <FormControlLabel
                  sx={{ margin: 0, '& .MuiButtonBase-root': { padding: 0 }, width: mdDown ? '100%' : 'inherit' }}
                  key={index}
                  value={type.value === '' ? '' : type.value}
                  control={
                    <Radio color="secondary" id="otherField" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} />
                  }
                  label={
                    type.label === 'Άλλο' ? (
                      <Box
                        pl={3}
                        maxWidth={xxsDown ? 'calc(100% - 24px)' : 'inherit'}
                        onClick={() => {
                          setStepThreeState({ ...stepThreeState, payment_type: 'other' });
                        }}
                      >
                        <PigogoTextField
                          disabled={stepThreeState.payment_type !== 'other'}
                          sx={{
                            width: xsOnly ? '100%' : '316px',
                            maxWidth: '100%',
                            '& .MuiInputLabel-root': {
                              pointerEvents: 'all',
                              cursor: 'pointer',
                              width: stepThreeState.payment_type !== 'other' ? (xsOnly ? '100%' : '270px') : 'auto',
                            },
                            '& .Mui-disabled': {
                              cursor: 'pointer',
                            },
                          }}
                          onFocus={() => {
                            setFormErrors({ ...formErrors, payment_type: false });
                          }}
                          label="Άλλο"
                          value={otherPaymentType}
                          setValue={(value) => {
                            setOtherPaymentType(value);
                          }}
                        />
                      </Box>
                    ) : (
                      <Typography variant="mediumM" pl={3} color={'#313D53'}>
                        {type.label}
                      </Typography>
                    )
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
          {formErrors.payment_type && (
            <Typography variant={'caption'} color={'#F75676'} pl={3} mt={-1} component="p">
              Το πεδίο είναι υποχρεωτικό.
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <FormControlLabel
          sx={{
            margin: '8px 0',
            '& .MuiCheckbox-root': {
              width: '24px',
              height: '24px',
              marginRight: 0,
            },
            '& .MuiCheckbox-root.Mui-checked': {
              outlineOffset: '-4px',
              outlineWidth: '4px',
            },
          }}
          control={
            <PigogoCheckbox
              checked={stepThreeState.has_coupon_code}
              setValue={() => {
                setFormErrors({ ...formErrors, order_coupon_code: false });
                setStepThreeState({ ...stepThreeState, has_coupon_code: !stepThreeState.has_coupon_code });
              }}
              inputProps={{ color: 'secondary', sx: { padding: 0 } }}
            />
          }
          label={
            <Typography variant="mediumM" pl={3} color={'#313D53'}>
              Χρησιμοποίησα εκπτωτικό κουπόνι κατά την αγορά μου
            </Typography>
          }
        />
        {stepThreeState.has_coupon_code && (
          <PigogoTextField
            sx={{ width: '316px' }}
            onFocus={() => {
              setFormErrors({ ...formErrors, order_coupon_code: false });
            }}
            value={stepThreeState.order_coupon_code}
            setValue={(value) => {
              setStepThreeState({ ...stepThreeState, order_coupon_code: value });
            }}
            label="Κωδικός κουπονιού"
            error={formErrors.order_coupon_code}
            helperText={formErrors.order_coupon_code && 'Το πεδίο είναι υποχρεωτικό.'}
          />
        )}
      </Box>
      <Divider />
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Typography variant="mediumM" color={'#1D2532'} component="h4">
          Επιβεβαιωτικό email αγοράς από το κατάστημα
        </Typography>
        <Box display="flex" flexDirection={smDown ? 'column' : 'row'} gap={2}>
          <DatePicker
            width="316px"
            placeholder="HH/MM/EEEE"
            label="Ημερομηνία λήψης"
            handleChange={handleDateChange}
            error={formErrors.confirmation_date}
            value={stepThreeState.confirmation_date}
            overSixMonths={dayjs(stepThreeState.confirmation_date).isBefore(
              dayjs().startOf('day').subtract(6, 'month'),
            )}
            validateFutureDate
          />
          <TimePicker
            width="316px"
            label="Ώρα λήψης"
            placeholder="ΩΩ:ΛΛ"
            handleChange={handleTimeChange}
            error={formErrors.confirmation_time}
            value={stepThreeState.confirmation_time}
          />
        </Box>
      </Box>

      {!!shopDisplayConfirmation && (
        <FormControlLabel
          sx={{
            margin: 0,
            '& .MuiCheckbox-root': {
              width: '24px',
              height: '24px',
              marginRight: 0,
            },
            '& .MuiCheckbox-root.Mui-checked': {
              outlineOffset: '-4px',
              outlineWidth: '4px',
            },
          }}
          control={
            <PigogoCheckbox
              checked={!stepThreeState.has_confirmation_email}
              setValue={() => {
                setFormErrors({ ...formErrors, confirmation_email: false });
                setStepThreeState({
                  ...stepThreeState,
                  has_confirmation_email: !stepThreeState.has_confirmation_email,
                });
              }}
              inputProps={{ color: 'secondary', sx: { padding: 0 } }}
            />
          }
          label={
            <Typography variant="mediumM" pl={3} color={'#313D53'}>
              Δεν έχω λάβει email επιβεβαίωσης από το κατάστημα
            </Typography>
          }
        />
      )}
      <Box display={'flex'} flexDirection={'column'} gap={3} maxWidth={'720px'}>
        <Box display={'flex'} flexDirection={'row'} gap={2} maxWidth={'720px'}>
          <Typography variant="mediumM" color={'#1D2532'} component="h4">
            Αντίγραψε εδώ (copy paste) το περιεχόμενο του email
          </Typography>
          {!shopDisplayConfirmation && (
            <Box
              onMouseOver={MouseOver}
              onMouseOut={MouseOut}
              display={'inline-flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ cursor: 'pointer', margiTop: 3 }}
            >
              <RichTooltip
                content={
                  <Typography variant={'caption'} component="p">
                    Μπορείς να σβήσεις προσωπικά στοιχεία που δεν επιθυμείς να αντιγράψεις όπως όνομα, διεύθυνση και
                    στοιχεία πληρωμής
                  </Typography>
                }
                open={open}
                placement="bottom"
                color="dark"
                onClose={() => setOpen(false)}
              >
                <Infolight color={'#1D2532'} />
              </RichTooltip>
            </Box>
          )}
        </Box>
        <PigogoTextArea
          inputProps={{
            sx: {
              '& .MuiTextField-root': {
                margin: theme.spacing(1),
                minHeight: '208px',
              },
              '& .MuiInputBase-inputMultiline': {
                height: '100% !important',
              },
              '& .MuiInputBase-multiline': {
                height: '208px',
              },
              '& textarea': {
                height: '100%',
              },
              width: '100%',
              minHeight: '208px',
            },
            rows: 4,
          }}
          error={formErrors.confirmation_email}
          value={stepThreeState.confirmation_email}
          setValue={(value: any) => {
            setFormErrors({ ...formErrors, confirmation_email: false });
            setStepThreeState({ ...stepThreeState, confirmation_email: value });
          }}
          placeholder="Αντιγράφοντας εδώ το email που έλαβες, αυξάνεις έτσι τις πιθανότητες να εγκριθεί το αίτημά σου!"
        />
        {formErrors.confirmation_email && (
          <Typography variant={'caption'} color={'#F75676'} pl={3} component="p" sx={{ marginTop: '-16px' }}>
            Το πεδίο είναι υποχρεωτικό.
          </Typography>
        )}
      </Box>

      <Divider />

      <Box display={'flex'} flexDirection={'column'} gap={3} maxWidth={'720px'}>
        <Typography variant="mediumM" color={'#1D2532'} component="h4">
          Επιπλέον σχόλια
        </Typography>
        <PigogoTextArea
          inputProps={{
            multiline: true,
            rows: 6,
            sx: {
              '& .MuiTextField-root': {
                margin: theme.spacing(1),
              },
              width: '100%',
            },
          }}
          value={stepThreeState.user_note}
          setValue={(value) => setStepThreeState({ ...stepThreeState, user_note: value })}
          placeholder="Εδώ μπορείς να αναφέρεις οτιδήποτε θεωρείς χρήσιμο για τη συγκεκριμένη αγορά."
        />
      </Box>
    </Box>
  );
};

export default StepThreeContent;
