import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomContainer from '../CustomContainer';
import RegisterFloatingZone from './RegisterFloatingZone';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as Line } from '../../assets/line.svg';
import { ReactComponent as PlayButton } from '../../assets/svgs/playButton.svg';
import hero from '../../assets/jpg/heroSection.jpg';
import {
  setAccessToken,
  setClosedAlert,
  setFirstLogin,
  setRefreshToken,
  setSeenAlert,
  setSessionLoggedIn,
} from '../../redux/slices/authenticationSlice';
import { useLazyObtainCsrfTokenQuery, useLoginMutation } from '../../redux/api/mutations/loginMutations';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { theme } from 'components';
import '../../scss/components/hero.css';
import { isWeb } from '../../utils/device';
import { Device } from '@capacitor/device';
import { setSnackBar } from '../../redux/slices/layoutSlice';
import { createGenericEvent } from '../../react-gtm-helpers';
import Cookies from 'js-cookie';

interface Props {
  setVideo: (value: boolean) => void;
  onclickSignIn: () => void;
}

const HeroSection: FC<Props> = ({ setVideo, onclickSignIn }) => {
  const dispatch = useDispatch();
  const web = isWeb(); //const desktop = false;

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const xlUp = useMediaQuery(theme.breakpoints.up('extraLg'));
  const xxlUp = useMediaQuery(theme.breakpoints.up('xl'));
  const lgDown = useMediaQuery(theme.breakpoints.down('extraLg'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [login] = useLoginMutation();

  const loginRequest = async (accessToken: string, type: string, userId?: string) => {
    if (web) {
      const csrf: any = await fetch(process.env['REACT_APP_API_XSRF_TOKEN_URL'] ?? '', {
        credentials: 'include',
      });
    }
    const devName = (await Device.getId()).identifier;
    const loginResponse: any = await login({
      provider: type,
      rememberMe: true,
      social_access_token: accessToken,
      devName: devName,
    });
    if (loginResponse && loginResponse.error) {
      dispatch(setSnackBar({ value: true, msg: (loginResponse.error as any).data?.message, severity: 'error' }));
      if (
        type == 'facebook' &&
        (loginResponse.error as any).data?.message.includes(
          'Η εγγραφή σου απέτυχε καθώς δε βρέθηκε e-mail στον Facebook λογαριασμό σου.',
        )
      ) {
        const response = await fetch(
          'https://graph.facebook.com/v22.0/' + userId + '/permissions' + '?access_token=' + accessToken,
          {
            method: 'DELETE',
            headers: {
              'Content-type': 'application/json',
            },
          },
        );
        console.log(response);
      }
      return;
    }
    if (loginResponse.data.resource_created) {
      dispatch(setFirstLogin({ isFirst: true }));
      dispatch(setSeenAlert({ seenAlert: false }));
      dispatch(setClosedAlert({ closedAlert: false }));

      if (loginResponse.data.uid) {
        createGenericEvent('Successful Registration', {
          'User ID': loginResponse.data.uid,
        });
      }
    }
    if (web) {
      if (loginResponse?.data) {
        dispatch(setSessionLoggedIn({ sessionLoggedIn: true, rememberMe: true }));
        dispatch(setSeenAlert({ seenAlert: false }));
      } else {
        dispatch(setSessionLoggedIn({ sessionLoggedIn: false }));
      }
    } else {
      if (loginResponse?.data) {
        setTimeout(() => {
          dispatch(setAccessToken({ token: loginResponse?.data.access_token, rememberMe: true }));
          dispatch(setRefreshToken({ refresh_token: loginResponse?.data.refresh_token }));
        }, 200);
      }
    }
  };

  const handleFacebook = async () => {
    try {
      const result = await FacebookLogin.login({ permissions: ['public_profile', 'email'] });
      // Task 556: We still need the FB userId of user in order to revoke permissions if no email is found.
      // Currently, FacebookLogin.login(..) does not return userId of user so we have to get it from FacebookLogin.getCurrentAccessToken()
      const getCurrentAccessTokenResponse = await FacebookLogin.getCurrentAccessToken();
      if (result.accessToken) {
        // Login successful.
        loginRequest(result.accessToken.token, 'facebook', getCurrentAccessTokenResponse.accessToken?.userId);
      }
    } catch (e) {
      console.log(e);
      Cookies.remove('c_user', { httpOnly: true });
      Cookies.remove('_fbp');
      return;
    }
  };

  const handleGoogle = async () => {
    try {
      const res = await GoogleAuth.signIn();
      if (res && res.authentication && res.authentication.accessToken) {
        const access = res.authentication.accessToken;
        loginRequest(access, 'google');
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
          zIndex: 1000,
          height: lgDown ? (mdDown ? (smDown ? '808px' : '748px') : '608px') : '640px',
          width: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            position: 'relative',
            height: lgDown ? (mdDown ? (smDown ? '808px' : '748px') : '608px') : '640px',
            background:
              'linear-gradient(0deg, rgba(49, 61, 83, 0.8), rgba(49, 61, 83, 0.8)), linear-gradient(98.72deg, rgba(16, 31, 75, 0.4) 0%, rgba(233, 104, 138, 0.4) 100%)',
          }}
        >
          <img
            src={hero}
            alt={'hero-section'}
            style={{
              top: 0,
              left: 0,
              opacity: 0.2,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </div>
        <Box
          sx={{
            paddingBlock: lgDown ? (mdDown ? '40px' : '76px') : '84px',
            position: 'absolute',
            top: '0%',
            bottom: '0%',
            width: '100%',
          }}
        >
          <CustomContainer px={lgUp ? '131px' : smDown ? '16px' : '32px'}>
            <Grid className="heroContainer" container gap={mdDown ? 2 : 0}>
              <Grid
                className="heroBox"
                item
                md={6}
                xs={12}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems={'flex-start'}
              >
                <Box sx={{ minWidth: lgUp ? '524px' : 'inherit' }}>
                  <Typography variant="h1" component="h1">
                    Κέρδισε χρήματα
                    <span style={{ color: '#E9688A', display: xlUp ? 'block' : smDown ? 'block' : 'inline-block' }}>
                      {' '}
                      για κάθε online
                    </span>{' '}
                    αγορά σου
                  </Typography>
                </Box>
                <Box sx={{ minWidth: xxlUp ? '524px' : lgUp ? '460px' : 'inherit' }}>
                  <Typography variant="bodyLargeR" component="h2">
                    Κάνε τις αγορές σου από τα ηλεκτρονικά καταστήματα του Pigogo και κέρδισε επιστροφή χρημάτων
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  gap={mdDown ? 2 : 4}
                  alignItems="center"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setVideo(true)}
                >
                  <Typography color="white" variant="button" fontWeight={mdDown ? 500 : 600}>
                    ΔΕΣ ΤΟ VIDEO
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Line />
                    <IconButton>
                      <PlayButton />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                md={6}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent={mdDown ? 'flex-start' : 'flex-end'}
              >
                <RegisterFloatingZone
                  heroSection
                  header="Γίνε μέλος δωρεάν!"
                  onClickGoogle={() => handleGoogle()}
                  onClickSignIn={() => onclickSignIn()}
                  onClickFacebook={() => handleFacebook()}
                />
              </Grid>
            </Grid>
          </CustomContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
