import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { PigogoButton } from 'components';
import Lottie from 'react-lottie';
import { ReactComponent as StarFilled } from '../../assets/svgs/stars/light/filled.svg';
import { ReactComponent as HalfStar } from '../../assets/svgs/stars/light/half.svg';
import * as animationData from '../../assets/lottie.json';
import { ReactComponent as Chrome } from '../../assets/svgs/searchApp/chromeWhite.svg';
import { ReactComponent as Firefox } from '../../assets/svgs/searchApp/firefoxWhite.svg';
import CustomContainer from '../CustomContainer';
import detectBrowser from '../../utils/browserDetection';
import { makeStyles } from '@mui/styles';
import { useIonRouter } from '@ionic/react';
import { Link } from 'react-router-dom';
import PigogoAlertStaticZone from '../../assets/jpg/pigogo_alert_static_zone.jpg';

// const useStyles = makeStyles((theme) => ({
//   alertText: {
//     backgroundColor: 'red',
//     [theme.breakpoints.up('md')]: {
//       paddingLeft: '48px',
//       width: '496px',
//     },
//   },
// }));

const useStyles = makeStyles({
  alertText: {
    maxWidth: '352px',
    '@media (min-width: 1367px)': {
      maxWidth: '384px',
    },
  },
});

const AlertSection = () => {
  const router = useIonRouter();
  const down1666 = useMediaQuery('(max-width:1666px)');
  const down1200 = useMediaQuery('(max-width:1200px)');
  const down1020 = useMediaQuery('(max-width:1020px)');

  const browser = detectBrowser();

  const classes = useStyles();

  const calculateRight = () => {
    if (down1020) {
      return '-170px';
    }
    if (down1200) {
      return '-280px';
    }
    return '-120px';
  };

  const renderPigogoAlertBox = () => {
    return (
      <Box
        pt={10}
        zIndex={2}
        pb={'60px'}
        display="flex"
        width={'384px'}
        overflow={'hidden'}
        position={'relative'}
        flexDirection="column"
      >
        <Typography color="white" variant="h3" component="h2" pb={'24px'}>
          Pigogo ALERT!
        </Typography>

        <Typography
          pb={'27px'}
          color="white"
          variant="body1"
          fontWeight={300}
          sx={{ lineHeight: '1.4', letterSpacing: '-0.02em' }}
          className={classes.alertText}
        >
          Εγκατάστησε το Pigogo ALERT στον browser σου για να σου υπενθυμίζει την επιστροφή χρημάτων όποτε επισκέπτεσαι
          ένα συνεργαζόμενο κατάστημα.
        </Typography>

        <Box display={'flex'} flexDirection={'column'} gap={'7px'}>
          <Box display="flex" gap={'6px'}>
            <StarFilled />
            <StarFilled />
            <StarFilled />
            <StarFilled />
            <HalfStar />
          </Box>
          <Typography color="white" variant="subtitle2">
            το έχουν κατεβάσει 5.000+ χρήστες
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={3} pt={'48px'} sx={{ maxWidth: '327px' }}>
          <Box display={'flex'} gap={2} justifyContent={'space-between'}>
            {(browser === 'Chrome' || browser === 'other') && (
              <a
                className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth pigogoButton--icon"
                href={'https://chrome.google.com/webstore/detail/pigogo-alert/adhfocpeiaaklpfbaipnjehejfbghjeg?hl=el'}
                target="_blank"
              >
                <Chrome />
                {browser === 'Chrome' ? `Εγκατάσταση στον Chrome` : `Google Chrome`}
              </a>
            )}
            {(browser === 'Firefox' || browser === 'other') && (
              <a
                className="pigogoButton pigogoButton--secondary pigogoButton--fullwidth pigogoButton--icon"
                href={'https://addons.mozilla.org/el/firefox/addon/pigogo-alert/'}
                target="_blank"
              >
                <Firefox />
                {browser === 'Firefox' ? `Εγκατάσταση στον Mozilla Firefox` : `Mozilla Firefox`}
              </a>
            )}
          </Box>
          <Box>
            <Link
              className="pigogoButton pigogoButton--white pigogoButton--outlined pigogoButton--fullwidth"
              to={'/alert'}
            >
              Διάβασε περισσότερα
            </Link>
          </Box>
        </Box>
      </Box>
    );
  };
  const renderPigogoAlertAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <Box
        top={0}
        right={calculateRight()}
        zIndex={1}
        height={'580px'}
        width={down1020 ? '100%' : 'inherit'}
        sx={{
          position: down1020 ? 'static' : 'absolute',
        }}
      >
        <Lottie options={defaultOptions} speed={1.4} />
      </Box>
    );
  };

  const renderPigogoAlertStatic = () => {
    return (
      <Box
        top={0}
        right={-100}
        zIndex={1}
        height={'580px'}
        width={down1020 ? 'max-content' : 'inherit'}
        sx={{
          position: down1020 ? 'static' : 'absolute',
        }}
      >
        <img
          src={PigogoAlertStaticZone}
          alt={'pigogo-alert-static-zone'}
          style={{
            opacity: 1,
            height: down1020 ? '100%' : down1200 ? '95%' : '100%',
            objectFit: 'cover',
            left: '100%',
            marginLeft: down1020 ? -445 : 25,
          }}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ backgroundColor: '#1D2532', minHeight: '580px', overflow: 'hidden' }}>
      <CustomContainer>
        {renderPigogoAlertBox()}
        {(browser === 'Chrome' || browser === 'Firefox') && renderPigogoAlertAnimation()}
        {browser === 'other' && renderPigogoAlertStatic()}
      </CustomContainer>
    </Box>
  );
};

export default AlertSection;
