import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import AccordionCard from './AccordionCard';
import { PigogoButton, PigogoCheckboxCircle, PigogoDropdown, theme } from 'components';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ReactComponent as Unchecked } from '../../../assets/svgs/unchecked.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/svgs/checked.svg';
import { UserData } from '../../../models/User';
import { PaymentThreshold } from '../../../models/PaymentThreshold';
import { useUpdateUserMutation } from '../../../redux/api/mutations/userMutations';
import useDebounce from '../../../hooks/useDebounce';
import { useDispatch } from 'react-redux';
import { setLoad, setSnackBar, setStateSnackBar } from '../../../redux/slices/layoutSlice';
import IbanField from './IbanField';
import BeneficiaryField from './BeneficiaryField';
import { UpdateUserBankDetails } from '../../../redux/api/types/UpdateUserBankDetails';
import { useIbanThresholdsMutation } from '../../../redux/api/mutations/paymentThresholdsMutation';
import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import { Link } from 'react-router-dom';

interface Props {
  userData?: UserData;
  loading: boolean;
  setUpdateError: (value: boolean) => void;
}

const BankCard: FC<Props> = ({ userData, loading, setUpdateError }) => {
  const dispatch = useDispatch();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [getIbanThresholds, thresholdsResult] = useIbanThresholdsMutation();
  const [updateBankDetails, { isLoading: updateLoad, isError: updateError, error: updateErr, reset: resetUser }] =
    useUpdateUserMutation();

  const [iban, setIban] = useState('');
  const [threshold, setThreshold] = useState('');
  const [beneficiary, setBeneficiary] = useState('');
  const [expanded, setExpanded] = useState<boolean>(false);
  const [defaultMethod, setDefaultMethod] = useState(false);
  const [ibanError, setIbanError] = useState<boolean>(false);
  const [errorBeneficiary, setErrorBeneficiary] = useState<boolean>(false);
  const [errorThreshold, setErrorThreshold] = useState<boolean>(false);
  const [leaving, setLeaving] = useState<boolean>(false);
  const [thresholds, setThresholds] = useState<PaymentThreshold[]>([]);

  const [resetError, setResetError] = useState<boolean>(true);

  const debouncedIban = useDebounce(iban.replace(/\s+/g, ''), 500);

  useIonViewDidEnter(() => {
    setLeaving(false);
  });

  useIonViewDidLeave(() => {
    setLeaving(true);
  });

  useEffect(() => {
    if (leaving) {
      resetUser();
    }
  }, [leaving]);

  useEffect(() => {
    if (updateError) {
      setUpdateError(true);
      const err = updateErr as any;
      if (err && err.data && !err.data.payment_bank_iban) {
        const params = {
          value: true,
          msg: 'To αίτημα ενημέρωσης απέτυχε!',
          severity: 'error',
        };
        dispatch(setSnackBar(params));
      }

      if (err && err.data && err.data.payment_bank_iban) {
        setIbanError(true);
      }
    } else {
      setUpdateError(false);
      dispatch(setStateSnackBar(false));
    }
  }, [updateError]);

  useEffect(() => {
    if (userData) {
      if (!thresholds.length && userData?.payment_bank_iban) {
        getIbanThresholds({ iban: userData?.payment_bank_iban ?? '' }).then((res) => {
          if (userData.payment_bank_iban) {
            setIbanError(false);
            setDefaultMethod(userData.payment_method_id === 1);
          }
          setIban(userData.payment_bank_iban ?? '');
          setBeneficiary(userData.payment_bank_account_holder ?? '');
          if (res && (res as any).data) {
            const threshold = (res as any).data.find((t: any) => t.id === userData?.payment_threshold_bank_id);
            setThreshold(threshold?.value ?? '');
          }
        });
      } else {
        if (userData.payment_bank_iban) {
          setIbanError(false);
          setDefaultMethod(userData.payment_method_id === 1);
        }
        setIban(userData.payment_bank_iban ?? '');
        setBeneficiary(userData.payment_bank_account_holder ?? '');
      }
    }
  }, [userData]);

  useEffect(() => {
    if (thresholds) {
      const threshold = thresholds.find((t) => t.id === userData?.payment_threshold_bank_id);
      setThreshold(threshold?.value ?? '');
    }
  }, [userData, thresholds]);

  useEffect(() => {
    dispatch(setLoad(updateLoad));
  }, [updateLoad]);

  useEffect(() => {
    if (debouncedIban) {
      getIbanThresholds({ iban: debouncedIban });
    }
  }, [debouncedIban]);

  useEffect(() => {
    if (thresholdsResult && thresholdsResult.data) {
      setThresholds(thresholdsResult.data);
    }
    if (thresholdsResult.error) {
      const error = thresholdsResult.error as any;
      if (error.status === 500) {
        const params = { value: true, msg: error.data.iban[0], severity: 'error' };
        dispatch(setSnackBar(params));
      }
      setIbanError(true);
    }
  }, [thresholdsResult]);

  useEffect(() => {
    setIbanError(false);
  }, [iban]);

  useEffect(() => {
    setErrorBeneficiary(false);
  }, [beneficiary]);

  useEffect(() => {
    setErrorThreshold(false);
  }, [threshold]);

  const disabled = () => {
    if (userData?.payment_bank_iban) {
      const prevThreshold = thresholds.find((t) => t.id === userData?.payment_threshold_bank_id);
      const prevDefaultMethod = userData?.payment_method_id === 1;

      const paymentIbanEqual = iban === userData.payment_bank_iban;
      const defaultMethodEqual = defaultMethod === prevDefaultMethod;
      const beneficiaryEqual = beneficiary === userData.payment_bank_account_holder;
      const thresholdEqual = threshold === prevThreshold?.value;
      return (
        (paymentIbanEqual && defaultMethodEqual && beneficiaryEqual && thresholdEqual) ||
        ibanError ||
        errorBeneficiary ||
        errorThreshold
      );
    } else {
      return (
        ibanError ||
        threshold === '' ||
        beneficiary === '' ||
        iban === '' ||
        ibanError ||
        errorBeneficiary ||
        errorThreshold
      );
    }
  };

  const hasData = useCallback(() => {
    return !!(iban || threshold || beneficiary);
  }, [iban, threshold, beneficiary]);

  const hasFees = useCallback(() => {
    return !thresholds?.find((result) => result.value === threshold)?.no_deposit_fee;
  }, [thresholds, threshold]);

  const getThresholds = useCallback(() => {
    return thresholds.map((threshold) => threshold.value);
  }, [thresholds]);

  const handleDefaultPaymentMethod = () => {
    updateBankDetails({ payment_method_id: '1' });
  };

  const handleSubmit = () => {
    const data: UpdateUserBankDetails = {
      payment_bank_iban: iban.replace(/\s+/g, ''),
      payment_bank_account_holder: beneficiary.trim(),
      payment_threshold_id: thresholds.find((thresholdItem) => thresholdItem.value === threshold)?.id || '',
    };

    let hasError = false;

    if (data.payment_bank_iban.trim() === '') {
      setIbanError(true);
      hasError = true;
    }

    if (
      data.payment_bank_account_holder === '' ||
      data.payment_bank_account_holder.length < 2 ||
      data.payment_bank_account_holder.length > 200 ||
      !data.payment_bank_account_holder.match(/^[\p{L}\s.\-&,]+$/u)
    ) {
      setErrorBeneficiary(true);
      hasError = true;
    }

    if (data.payment_threshold_id === '') {
      setErrorThreshold(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (!userData?.viva_wallet_phone_number) {
      data.payment_method_id = 1;
      setDefaultMethod(true);
    } else if (defaultMethod) {
      data.payment_method_id = 1;
    }

    updateBankDetails(data);
    setExpanded(true);
  };

  const determineErrorMsg = () => {
    if (updateErr && (updateErr as any).data && ibanError) {
      return (
        'Το συγκεκριμένο IBAN δεν μπορεί να χρησιμοποιηθεί. Παρακαλούμε χρησιμοποίησε' +
        ' κάποιο άλλο ή επικοινώνησε μαζί μας.'
      );
    } else if (ibanError && iban.trim().length === 0) {
      return 'Το πεδίο είναι υποχρεωτικό.';
    } else if (ibanError && thresholdsResult && thresholdsResult.error) {
      return (thresholdsResult.error as any).data &&
        (thresholdsResult.error as any).data.iban &&
        (thresholdsResult.error as any).data.iban[0]
        ? (thresholdsResult.error as any).data.iban[0]
        : 'Το ΙΒΑΝ δεν φαίνεται να είναι σωστό.';
    }

    return '';
  };

  const determineBeneficiaryErrorMsg = () => {
    if (errorBeneficiary && beneficiary.trim() === '') {
      return 'Το πεδίο είναι υποχρεωτικό.';
    } else if (errorBeneficiary && beneficiary.trim().length < 2) {
      return 'Το πεδίο πρέπει να αποτελείται από τουλάχιστον 2 χαρακτήρες.';
    } else if (errorBeneficiary && beneficiary.trim().length > 200) {
      return 'Οι χαρακτήρες δεν πρέπει να ξεπερνούν τους 200.';
    } else if (errorBeneficiary && !beneficiary.trim().match(/^[\p{L}\s.\-&,]+$/u)) {
      return 'O δικαιούχος πρέπει να αποτελείται από αλφαβητικούς χαρακτήρες.';
    }

    return '';
  };

  const handleCancelClick = () => {
    if (userData) {
      setIbanError(false);
      setErrorBeneficiary(false);
      setErrorThreshold(false);

      setIban(userData.payment_bank_iban ?? '');
      setDefaultMethod(userData.payment_method_id === 1);
      setBeneficiary(userData.payment_bank_account_holder ?? '');

      const threshold = thresholds.find((t) => t.id === userData?.payment_threshold_bank_id);
      setThreshold(threshold?.value ?? '');
    }

    setExpanded(false);
  };

  const getButtons = () => {
    return (
      <Box display={'flex'} alignItems={'center'} gap={2}>
        {hasData() && (
          <Box>
            <PigogoButton
              fullWidth
              simpleButton
              text={'Ακύρωση'}
              variant={'outlined'}
              onClick={() => handleCancelClick()}
              sx={{ padding: '16px 24px', borderBottom: '1px solid black' }}
            />
          </Box>
        )}
        <Box>
          <PigogoButton
            fullWidth
            simpleButton
            text={'Αποθήκευση'}
            disabled={disabled() || !thresholdsResult.isSuccess}
            onClick={() => handleSubmit()}
            sx={{ padding: '16px 24px' }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <AccordionCard
        bank
        minimumAmount={5}
        loading={loading}
        hasData={hasData()}
        isExpanded={expanded}
        defaultMethod={userData?.payment_method_id === 1 && !!userData?.payment_bank_iban}
        paymentDetails={{ iban, beneficiary, threshold, defaultMethod }}
        setCheck={() => handleDefaultPaymentMethod()}
        setIsExpanded={(value) => (value ? setExpanded(value) : handleCancelClick())}
      >
        <Box display={'flex'} flexDirection={'column'} gap={5} pt={3}>
          <Box display={'flex'} flexDirection={'column'} gap={3}>
            <IbanField
              iban={iban}
              errorIban={ibanError}
              helperText={determineErrorMsg()}
              successThreshold={thresholdsResult.isSuccess}
              setIban={(iban) => {
                setIban(iban);
              }}
              inputProps={{
                onFocus: () => {
                  setIbanError(false);
                },
                onBlur: () => {
                  if (iban.trim().length === 0) {
                    setIbanError(true);
                  } else if (ibanError && thresholdsResult && thresholdsResult.error) {
                    setIbanError(true);
                  } else {
                    setIbanError(false);
                  }
                },
              }}
            />
            <BeneficiaryField
              beneficiary={beneficiary}
              setBeneficiary={(beneficiary) => {
                setBeneficiary(beneficiary);
              }}
              errorBeneficiary={errorBeneficiary}
              errorMessage={determineBeneficiaryErrorMsg}
              inputProps={{
                onFocus: () => {
                  setErrorBeneficiary(false);
                },
                onBlur: () => {
                  if (
                    beneficiary.trim().length < 2 ||
                    beneficiary.trim().length > 200 ||
                    !beneficiary.trim().match(/^[\p{L}\s.\-&,]+$/u) ||
                    beneficiary.trim() === ''
                  ) {
                    setErrorBeneficiary(true);
                  } else {
                    setErrorBeneficiary(false);
                  }
                },
              }}
            />
          </Box>

          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography variant={'mediumM'} component="h4" color={'#1D2532'}>
                Ελάχιστο ποσό πληρωμής
              </Typography>
              <Typography variant={'subtitle1SmallR'} component="p" color={'#313D53'}>
                Διάλεξε το ελάχιστο ποσό που πρέπει να συγκεντρώσεις για να πραγματοποιηθεί η πληρωμή σου.
              </Typography>
            </Box>
            {!hasFees() && !thresholdsResult.isLoading && !!thresholds.length && threshold !== '' && (
              <Box p={2} display={'flex'} sx={{ borderRadius: '8px', background: '#ECEFF4', maxWidth: '356px' }}>
                <Typography variant={'captionM'} component="p" color={'#313D53'}>
                  Δεν υπάρχουν έξοδα μεταφοράς! To Pigogo καλύπτει και τα έξοδα εμβάσματος της τράπεζάς σου!
                </Typography>
              </Box>
            )}
            {hasFees() && !thresholdsResult.isLoading && !!thresholds.length && threshold !== '' && (
              <Box p={2} display={'flex'} sx={{ borderRadius: '8px', background: '#ECEFF4', maxWidth: '356px' }}>
                <Typography variant={'captionM'} component="p" color={'#313D53'}>
                  Θα επιβαρυνθείς με τα έξοδα εμβάσματος της τράπεζάς σου για ποσά κάτω από 30€. Διάβασε περισσότερα{' '}
                  <Link
                    to={'/faq'}
                    target={'_blank'}
                    style={{ color: '#313D53', textDecorationColor: '#313D53', fontWeight: '500' }}
                  >
                    εδώ
                  </Link>
                  .
                </Typography>
              </Box>
            )}
            <Box>
              {thresholdsResult.isLoading && (
                <Skeleton variant="rectangular" width="232px" height="62px" sx={{ borderRadius: '30px' }} />
              )}
              {!thresholdsResult.isLoading && (
                <Box width={'316px'} maxWidth={'100%'}>
                  <PigogoDropdown
                    width={'100%'}
                    size={'medium'}
                    options={getThresholds()}
                    value={ibanError ? '' : threshold}
                    disabled={!thresholdsResult.isSuccess || iban === '' || ibanError}
                    setSelected={(value: string) => {
                      setThreshold(value);
                    }}
                    label={iban === '' || ibanError ? 'Συμπλήρωσε πρώτα IBAN' : 'Επίλεξε ποσό'}
                    error={errorThreshold}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box display={'flex'} alignItems={smDown ? 'flex-start' : 'center'} gap={2} sx={{ position: 'relative' }}>
            <Box
              sx={{
                width: '24px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
              }}
            >
              <PigogoCheckboxCircle
                inputProps={{
                  disableRipple: true,
                  color: 'secondary',
                  icon: <Unchecked />,
                  checkedIcon: <CheckedIcon />,
                  sx: { '&': { width: '100%', height: '100%', alignItems: 'center !important', position: 'static' } },
                }}
                checked={defaultMethod && !!userData?.payment_bank_iban}
                setValue={() =>
                  setDefaultMethod((prev) => {
                    return !prev;
                  })
                }
              />
            </Box>
            {(userData?.payment_method_id !== 1 || !userData?.payment_bank_iban) && (
              <Typography variant={'mediumM'} component="p" color={'#313D53'}>
                Ορισμός ως επιλεγμένου τρόπος πληρωμής
              </Typography>
            )}
            {userData?.payment_method_id === 1 && !!userData?.payment_bank_iban && (
              <Typography variant={'mediumM'} component="p" color={'#313D53'}>
                Επιλεγμένος τρόπος πληρωμής
              </Typography>
            )}
          </Box>
          {getButtons()}
        </Box>
      </AccordionCard>
    </Box>
  );
};

export default BankCard;
