import { Box, BoxProps, Grid, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import PigogoChip from './PigogoChip';
import { Fire } from '../assets';

const StyleBox = styled(Box)(() => ({
  boxSizing: 'border-box',
  display: 'inline-flex',
  borderRadius: '24px',
  background: '#8C3E53',
  width: '100%',
}));

interface Props {
  title?: string;
  subTitle: string;
  date?: string;
  hotOffer?: boolean;
  boxProps?: BoxProps;
  onClick: () => void;
}

const PigogoStorePromo: FC<Props> = ({ title, subTitle, date, hotOffer, boxProps, onClick }) => {
  return (
    <StyleBox {...boxProps}>
      <Box style={{ padding: 24, width: '100%', height: '100%', zIndex: '100' }}>
        <Grid container style={{ height: '100%' }}>
          {title && (
            <Grid container item xs={12} alignItems={'flex-start'}>
              {!!hotOffer && (
                <Grid container item width={66}>
                  <PigogoChip width={66} size={'small'} color={'secondary'} label={'x2'} startIcon={<Fire />} />
                </Grid>
              )}
              {!hotOffer && (
                <Grid item width={33} height={32}>
                  <Box
                    width={33}
                    height={32}
                    borderRadius={24}
                    style={{
                      backgroundColor: '#E9688A',
                      paddingBottom: '1px',
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Fire />
                  </Box>
                </Grid>
              )}
              <Grid item style={{ paddingLeft: 20, paddingTop: 4 }}>
                <Typography variant={'body2'} style={{ color: '#F2A4B9' }}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12} alignItems={'end'} justifyContent={'start'} style={{ paddingTop: 26 }}>
            <Grid
              item
              xs={12}
              sx={{
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: '5',
              }}
            >
              <Typography variant={'body1'} style={{ color: '#fff' }}>
                {subTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 4 }}>
              <Typography variant={'subtitle1SmallRLS0'} style={{ color: '#fff' }}>
                {date}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </StyleBox>
  );
};

export default PigogoStorePromo;
