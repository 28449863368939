import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { PigogoValueLabelDropdown, RichTooltip, theme } from 'components';
import { FC, useEffect, useState } from 'react';
import { ClaimTypeId } from '../../../models/ClaimTypeId';
import { CreateClaimStepTwo } from '../../../models/CreateClaimStepTwo';
import { ReactComponent as InfoOutlined } from '../../../assets/svgs/info.svg';

import {
  useGetCanceledPurchasesShopsQuery,
  useLazyGetUserCanceledPurchasesQuery,
} from '../../../redux/api/queries/userQueries';
import StepTwoTableDesktop from './StepTwoTableDesktop';
import StepTwoTableMobile from './StepTwoTableMobile';
import { useAppDispatch } from '../../../redux/hooks';
import { setSnackBar } from '../../../redux/slices/layoutSlice';

interface Props {
  typeOfClaim: ClaimTypeId | 0;
  stepTwoState: CreateClaimStepTwo;
  setStepTwoState: (stepTwoState: CreateClaimStepTwo) => void;
}

const StepTwoCancelledContent: FC<Props> = ({ stepTwoState, setStepTwoState }) => {
  const dispatch = useAppDispatch();

  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));

  const [openToolTip, setOpenToolTip] = useState(false);

  const {
    isLoading,
    data: shopsList,
    error: errorShops,
    isError: isErrorShops,
    isSuccess: shopsSuccess,
  } = useGetCanceledPurchasesShopsQuery();

  const [triggerPurchases, { data: cancelledPurchases, isFetching, isError: isErrorPurchases, error: errorPurchases }] =
    useLazyGetUserCanceledPurchasesQuery();

  useEffect(() => {
    if (stepTwoState.shop_id !== '') triggerPurchases({ shop: stepTwoState.shop_id });
  }, [triggerPurchases, stepTwoState.shop_id]);

  useEffect(() => {
    if (isErrorPurchases) {
      dispatch(setSnackBar({ msg: 'Κάτι πήγε στραβά με τις αγορές!', severity: 'error', value: true }));
    } else {
      dispatch(setSnackBar({ msg: '', severity: '', value: false }));
    }

    if (isErrorShops) {
      dispatch(setSnackBar({ msg: 'Το αίτημα δεν καταχωρήθηκε!', severity: 'error', value: true }));
    } else {
      dispatch(setSnackBar({ msg: '', severity: '', value: false }));
    }
  }, [isErrorShops, isErrorPurchases, errorPurchases, errorShops, dispatch]);

  const extractTable = () => {
    if (!xsOnly) {
      return (
        <StepTwoTableDesktop
          loading={isFetching}
          stepTwoState={stepTwoState}
          setStepTwoState={setStepTwoState}
          cancelledPurchases={cancelledPurchases?.data}
        />
      );
    } else {
      return (
        <StepTwoTableMobile
          loading={isFetching}
          stepTwoState={stepTwoState}
          setStepTwoState={setStepTwoState}
          cancelledPurchases={cancelledPurchases?.data ?? []}
        />
      );
    }
  };

  const MouseOverShops = () => {
    setOpenToolTip(true);
  };

  const MouseOutShops = () => {
    setOpenToolTip(false);
  };

  const handleSelectShop = (e: any) => {
    setStepTwoState({
      ...stepTwoState,
      shop_id: e.target.value,
      email_confirmation_mandatory:
        shopsList?.data.filter((item) => item.shop_id == Number(e.target.value))[0].email_confirmation_mandatory ??
        false,
    });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3}>
      <Box display="flex" gap={2} flexDirection="column">
        <Typography variant="mediumM" color={'#1D2532'} component="h4">
          Ηλεκτρονικό κατάστημα
        </Typography>

        <Box display="flex" alignItems="center" gap={2}>
          {isLoading && <Skeleton width="316px" height="62px" sx={{ borderRadius: '30px' }} variant="rectangular" />}
          {!isLoading && (
            <PigogoValueLabelDropdown
              size="medium"
              fullWidth={xsOnly}
              disabled={!shopsSuccess}
              label={'Επίλεξε κατάστημα'}
              selected={stepTwoState.shop_id ?? ''}
              sx={{ width: !xsOnly ? '316px' : undefined }}
              handleChange={handleSelectShop}
              options={
                shopsList?.data?.map((shop) => {
                  return { label: shop.shop_name, value: shop.shop_id.toString() };
                }) ?? []
              }
            />
          )}
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              position: 'relative',
              '&:before': {
                content: '""',
                width: 'calc(100% + 12px)',
                height: 'calc(100% + 12px)',
                position: 'absolute',
                top: '-6px',
                left: '-6px',
              },
            }}
            onMouseOver={MouseOverShops}
            onMouseOut={MouseOutShops}
          >
            <RichTooltip
              content={
                <Typography variant="caption" component="p">
                  Εμφανίζονται μόνο τα καταστήματα για τα οποία υπάρχουν ακυρωμένες αγορές με ημερομηνία ακύρωσης έως 1
                  μήνα πριν
                </Typography>
              }
              open={openToolTip}
              placement="bottom"
              color="dark"
              onClose={MouseOutShops}
            >
              <InfoOutlined />
            </RichTooltip>
          </Box>
        </Box>
      </Box>
      {stepTwoState.shop_id !== '' && extractTable()}
    </Box>
  );
};

export default StepTwoCancelledContent;
