import React, { FC, useEffect } from 'react';
import { useIonRouter } from '@ionic/react';
import { Box } from '@mui/system';
import { Button, Popover, Typography } from '@mui/material';
import { ReactComponent as Gift } from '../../../assets/svgs/gift.svg';
import { useLogoutMutation } from '../../../redux/api/mutations/loginMutations';
import { UserData } from '../../../models/User';
import { setLoad } from '../../../redux/slices/layoutSlice';
import { useDispatch } from 'react-redux';
import ExtractSaver from '../../ExtractSaver';
import { ReactComponent as ChevronRight } from '../../../assets/svgs/chevronRight.svg';
import { Link } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  anchorEl: HTMLElement;
  userData?: UserData;
  handleDismiss: () => void;
}

const firstList = [
  { label: 'Σύνοψη', route: '/user/overview' },
  { label: 'Αγορές', route: '/user/purchases' },
  { label: 'Πληρωμές', route: '/user/payments' },
  { label: 'Αιτήματα', route: '/user/claims' },
];

const secondList = [
  { label: 'Στοιχεία λογαριασμού', route: '/user/personal-info' },
  { label: 'Στοιχεία πληρωμής', route: '/user/payments-options' },
  { label: 'Ρυθμίσεις επικοινωνίας', route: '/user/contact-options' },
];

const AccountMenu: FC<Props> = ({ userData, isOpen, anchorEl, handleDismiss }) => {
  const router = useIonRouter();
  const dispatch = useDispatch();

  const pathname = router.routeInfo.pathname;

  const [logout, { isLoading: loadLogout }] = useLogoutMutation();

  useEffect(() => {
    dispatch(setLoad(loadLogout));
  }, [dispatch, loadLogout]);

  const handleLogout = async () => {
    handleDismiss();
    await logout({});
    if (pathname.includes('user/')) {
      window.location.replace('/');
    }
  };

  const handleMenuClick = (pathname: string) => {
    router.push(pathname);
    handleDismiss();
  };

  const getSaverIcon = () => {
    if (userData && userData?.gamification_level_id !== undefined && userData?.gamification_level_id !== 0) {
      return <ExtractSaver saver={userData?.gamification_level_id} />;
    } else {
      return (
        <Typography color="#838B98" variant="subtitle1SmallR">
          Κάνε την πρώτη σου αγορά και γίνε{' '}
          <Typography color="#FC9E5F" display="inline" variant="subtitle1">
            Saver
          </Typography>
        </Typography>
      );
    }
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleDismiss}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableAutoFocus
      sx={{
        marginLeft: 3,
        zIndex: (theme) => theme.zIndex.modal + 3,
        '& .MuiPaper-root': {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
          width: '320px',
          borderRadius: '0 0 8px 8px',
        },
      }}
    >
      <Box
        p={3}
        gap={3}
        display={'flex'}
        flexDirection={'column'}
        boxSizing={'border-box'}
        borderTop={'1px solid #EAECEE'}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography
            variant="body2"
            maxWidth={'272px'}
            overflow={'hidden'}
            whiteSpace={'nowrap'}
            textOverflow={'ellipsis'}
            color={'#313D53'}
          >
            {userData?.first_name ? userData?.full_name : userData?.email}
          </Typography>
          {getSaverIcon()}
        </Box>

        <Box borderTop={'1px solid #EAECEE'}></Box>

        <Box>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {firstList.map((item, index) => (
              <Link key={index} className="sidebarBtn" to={item.route} onClick={() => handleMenuClick(item.route)}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: 'inherit',
                    letterSpacing: 'inherit',
                    lineHeight: 'inherit',
                  }}
                  fontWeight={pathname === item.route ? 700 : 'inherit'}
                >
                  {item.label}
                </Typography>
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ChevronRight />
                </Box>
              </Link>
            ))}
          </Box>
        </Box>

        <Box borderTop={'1px solid #EAECEE'}></Box>

        <Box>
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {secondList.map((item, index) => (
              <Link key={index} className="sidebarBtn" to={item.route} onClick={() => handleMenuClick(item.route)}>
                <Typography
                  component="span"
                  sx={{
                    fontSize: 'inherit',
                    letterSpacing: 'inherit',
                    lineHeight: 'inherit',
                  }}
                  fontWeight={pathname === item.route ? 700 : 'inherit'}
                >
                  {item.label}
                </Typography>
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <ChevronRight />
                </Box>
              </Link>
            ))}
          </Box>
        </Box>

        <Box borderTop={'1px solid #EAECEE'}></Box>

        <Box>
          <Link
            className="sidebarBtn tafBtn"
            to={'/user/tell-a-friend'}
            onClick={() => handleMenuClick('/user/tell-a-friend')}
          >
            <Typography
              component="span"
              variant="body2"
              fontWeight={pathname === '/user/tell-a-friend' ? 700 : undefined}
            >
              Tell a friend
            </Typography>
            <span style={{ display: 'inline-block', marginRight: '-4px', marginLeft: '8px' }}>
              <Gift />
            </span>
          </Link>
        </Box>

        <Box borderTop={'1px solid #EAECEE'}></Box>

        <Box>
          <Button className="sidebarBtn" onClick={handleLogout} sx={{ padding: 0, justifyContent: 'flex-start' }}>
            <Typography
              color="primary"
              variant="body2MediumM"
              sx={{ textDecoration: 'underline', textDecorationColor: '#FCE5D8', textUnderlineOffset: '5px' }}
            >
              Έξοδος
            </Typography>
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default AccountMenu;
