import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { PigogoButton, theme } from 'components';
import { FC } from 'react';

interface Props {
  title?: string;
  subtitle?: string;
  button?: string;
  loading?: boolean;
  handle?: () => void;
}

const HeroPromoCard: FC<Props> = ({ title, subtitle, button, handle, loading }) => {
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const xxlUp = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Box
      className={loading ? 'heroPromoCard heroPromoCard--loading' : 'heroPromoCard'}
      display="flex"
      flexDirection="column"
      gap={mdDown ? 4 : 7}
      width={xxlUp ? '524px' : lgUp ? '416px' : '100%'}
    >
      <Box display={'flex'} flexDirection={'column'} gap={mdDown ? 2 : 3}>
        <Box>
          <style>
            {`
            @media only screen and (max-width: 599px) {
              .heroTitle {
                overflow: hidden;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }
            `}
          </style>
          <Typography
            sx={{ textAlign: 'left' }}
            variant={mdDown ? 'mediumSB26' : 'h2'}
            component="h2"
            color="#FFFFFF"
            className={'heroTitle'}
          >
            {loading ? (
              <Box display="flex" flexDirection="column">
                <Skeleton sx={{ bgcolor: '#EAECEE' }} />
              </Box>
            ) : (
              title
            )}
          </Typography>
        </Box>

        <Box>
          <Typography
            sx={{
              overflow: 'hidden',
              WebkitLineClamp: '3',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              textAlign: 'left',
              minHeight: mdDown ? '80px' : '0px',
            }}
            variant={mdDown ? 'subtitle1SmallR' : 'largeL'}
            component="div"
            color="#FFFFFF"
          >
            {loading ? (
              <Box display="flex" flexDirection="column">
                <Skeleton sx={{ width: '290px', bgcolor: '#EAECEE' }} />
              </Box>
            ) : (
              subtitle
            )}
          </Typography>
        </Box>
      </Box>
      {loading && (
        <Skeleton
          sx={{
            bgcolor: '#EAECEE',
            width: '327px',
            height: '76px',
            maxWidth: '100%',
            borderRadius: '0px 32px 32px 32px',
          }}
        />
      )}
      {button && (
        <Box width={'327px'} maxWidth={'100%'}>
          {!loading && (
            <PigogoButton fullWidth variation="secondary" text={button} onClick={() => (handle ? handle() : '')} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default HeroPromoCard;
