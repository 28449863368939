import { Box, Button, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { PigogoClaimSelectableButton, PigogoValueLabelDropdown, RichTooltip, theme } from 'components';
import moment from 'moment';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ClaimTypeId } from '../../../models/ClaimTypeId';
import { CreateClaimStepTwo } from '../../../models/CreateClaimStepTwo';
import { useLazyGetUserClickoutsQuery } from '../../../redux/api/queries/userQueries';
import MissingClickModal from '../MissingClickModal';
import { setSnackBar } from '../../../redux/slices/layoutSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { ReactComponent as InfoOutlined } from '../../../assets/svgs/info.svg';

interface Props {
  stepTwoState: CreateClaimStepTwo;
  setStepTwoState: (stepTwoState: CreateClaimStepTwo) => void;
  setShopName: (value: string) => void;
  setShopTrackingSpeed: (value: number) => void;
  typeOfClaim: ClaimTypeId | 0;
}

const StepTwoContent: FC<Props> = ({
  stepTwoState,
  setStepTwoState,
  typeOfClaim,
  setShopName,
  setShopTrackingSpeed,
}) => {
  const dispatch = useAppDispatch();

  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [openToolTip, setOpenToolTip] = useState(false);
  const [missingClickModal, setMissingClickModal] = useState(false);

  const [trigger, { data: clickOuts, isSuccess, isLoading, isError, error }] = useLazyGetUserClickoutsQuery();

  useEffect(() => {
    const foundStoreDates: string[] = clickOuts?.data[stepTwoState.selectedShop];
    if (foundStoreDates) {
      const monthsToDisplay = Object.keys(foundStoreDates);
      setStepTwoState({ ...stepTwoState, months: monthsToDisplay });
    }
  }, [clickOuts?.data, stepTwoState, stepTwoState.selectedShop]);

  useEffect(() => {
    trigger({ order: 'asc', type: typeOfClaim.toString() });
  }, [trigger, typeOfClaim]);

  useEffect(() => {
    if (isError) {
      dispatch(setSnackBar({ msg: 'Κάτι πήγε στραβά με τα clickouts!', severity: 'error', value: true }));
    } else {
      dispatch(setSnackBar({ msg: '', severity: '', value: false }));
    }
  }, [isError]);

  const MouseOverShops = () => {
    setOpenToolTip(true);
  };

  const MouseOutShops = () => {
    setOpenToolTip(false);
  };

  const handleSelectDate = useCallback(
    (month: string, date: string) => {
      setStepTwoState({ ...stepTwoState, selectedMonth: month, selectedDate: date, selectedTime: '' });
    },
    [setStepTwoState, stepTwoState],
  );

  const handleSelectTime = useCallback(
    (id: string, time: string) => {
      setStepTwoState({ ...stepTwoState, clickout_id: id, selectedTime: time });
    },
    [setStepTwoState, stepTwoState],
  );

  const getShops = useMemo(() => {
    if (!clickOuts || !clickOuts.shops) return [];
    return clickOuts.shops.map((shop: { id: number; name: string; slug_name: string }) => ({
      value: shop.slug_name,
      label: shop.name,
    }));
  }, [clickOuts]);

  const getDates = useCallback(
    (month: string) => {
      const dates = Object.keys(clickOuts?.data[stepTwoState.selectedShop][month]);

      return dates?.map((date, index) => (
        <PigogoClaimSelectableButton
          key={index}
          selectedCategory={moment(stepTwoState.selectedDate, 'DD-MM-YYYY').format('DD MMMM YYYY')}
          text={moment(date, 'DD-MM-YYYY').format('DD MMMM YYYY')}
          handleClick={() => handleSelectDate(month, date)}
        />
      ));
    },
    [clickOuts?.data, handleSelectDate, stepTwoState.selectedDate, stepTwoState.selectedShop],
  );

  const getTimes = useMemo(() => {
    if (!clickOuts || !clickOuts.data) {
      return null;
    }
    if (!stepTwoState.selectedShop || !stepTwoState.selectedMonth || !stepTwoState.selectedDate) {
      return null;
    }
    const times = clickOuts.data[stepTwoState.selectedShop][stepTwoState.selectedMonth][stepTwoState.selectedDate];
    if (!times) {
      return null;
    }
    return times.map((time: any, index: number) => (
      <PigogoClaimSelectableButton
        key={index}
        selectedCategory={time.id === stepTwoState.clickout_id ? moment(time.time, 'HH:mm:ss').format('HH:mm') : ''}
        text={moment(time.time, 'HH:mm:ss').format('HH:mm')}
        handleClick={() => handleSelectTime(time.id, time.time)}
      />
    ));
  }, [
    clickOuts,
    handleSelectTime,
    stepTwoState.clickout_id,
    stepTwoState.selectedDate,
    stepTwoState.selectedMonth,
    stepTwoState.selectedShop,
  ]);

  const handleSelectShop = (e: any) => {
    const foundShop = clickOuts?.shops?.find((shop) => shop.slug_name === e.target.value);
    if (foundShop) {
      setStepTwoState({
        ...stepTwoState,
        months: [],
        selectedDate: '',
        selectedMonth: '',
        selectedShop: foundShop.name,
        slugName: foundShop.slug_name,
        shop_id: String(foundShop.id),
        email_confirmation_mandatory: foundShop.email_confirmation_mandatory ?? false,
      });
      setShopName(foundShop?.name);
      setShopTrackingSpeed(foundShop.tracking_speed ?? 2);
    }
  };

  return (
    <>
      <Box display="flex" gap={2} flexDirection="column">
        {xsOnly && (
          <Box mb={2}>
            <Button onClick={() => setMissingClickModal(true)} sx={{ padding: 0 }}>
              <Typography
                sx={{ textDecoration: 'underline', textDecorationColor: '#FCE5D8', textUnderlineOffset: '5px' }}
                color="primary"
                variant="buttonSmallM"
              >
                Γιατί δεν βρίσκω το κλικ μου;
              </Typography>
            </Button>
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={smDown ? 'column' : 'row'}
          gap={smDown ? 4 : 0}
        >
          <Typography variant="mediumM" component="p" color={'#1D2532'}>
            Ηλεκτρονικό κατάστημα
          </Typography>
          {!xsOnly && (
            <Button
              onClick={() => setMissingClickModal(true)}
              sx={{ padding: 0, order: smDown ? -1 : 'inherit', justifyContent: smDown ? 'left' : 'center' }}
            >
              <Typography
                sx={{ textDecoration: 'underline', textDecorationColor: '#FCE5D8', textUnderlineOffset: '5px' }}
                color="primary"
                variant="buttonSmallM"
              >
                Γιατί δεν βρίσκω το κλικ μου;
              </Typography>
            </Button>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          {isLoading && <Skeleton width="316px" height="62px" sx={{ borderRadius: '30px' }} variant="rectangular" />}
          {!isLoading && (
            <Box width={'356px'} maxWidth={'100%'}>
              <PigogoValueLabelDropdown
                fullWidth
                size="medium"
                options={getShops}
                disabled={!isSuccess}
                label={'Επίλεξε κατάστημα'}
                handleChange={handleSelectShop}
                selected={isSuccess ? stepTwoState.slugName : ''}
              />
            </Box>
          )}
          <Box
            sx={{
              width: '24px',
              height: '24px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              position: 'relative',
              '&:before': {
                content: '""',
                width: 'calc(100% + 12px)',
                height: 'calc(100% + 12px)',
                position: 'absolute',
                top: '-6px',
                left: '-6px',
              },
            }}
            onMouseOver={MouseOverShops}
            onMouseOut={MouseOutShops}
          >
            <RichTooltip
              color="dark"
              placement="bottom"
              open={openToolTip}
              content={
                <Typography variant="caption" component="p">
                  Εμφανίζονται μόνο τα ενεργά καταστήματα στα οποία έχεις πραγματοποιήσει κάποια επίσκεψη τους
                  τελευταίους 3 μήνες, μέσω Pigogo
                </Typography>
              }
              onClose={MouseOutShops}
            >
              <InfoOutlined />
            </RichTooltip>
          </Box>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Typography variant="mediumM" component="p" color={'#1D2532'}>
          Ημερομηνία επίσκεψης στο κατάστημα
        </Typography>

        <Box display="flex" flexDirection="column" gap={2} flexWrap="wrap">
          {stepTwoState.shop_id === '' || clickOuts === undefined || stepTwoState.months.length <= 0 ? (
            <Box
              style={{
                display: 'inline-flex',
                justifyContent: 'center',
                borderRadius: '88px',
                backgroundColor: '#F7F7F8',
                padding: '14px 16px',
                width: '205px',
              }}
            >
              <Typography variant={'subtitle1SmallR'} color={'#313D53'} textAlign={'center'} component="p">
                Επίλεξε πρώτα κατάστημα
              </Typography>
            </Box>
          ) : (
            stepTwoState.months.map((month, index) => (
              <Fragment key={index}>
                {stepTwoState.months.length > 1 && (
                  <Typography color="#6F7787" variant="inputLabel" component="p">
                    {moment(month, 'MM-YYYY').format('MMMM YYYY')}
                  </Typography>
                )}
                <Box display="flex" flexWrap={'wrap'} gap={1}>
                  {getDates(month)}
                </Box>
              </Fragment>
            ))
          )}
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Typography variant="mediumM" component="p" color={'#1D2532'}>
          Ώρα επίσκεψης στο κατάστημα
        </Typography>

        <Box display="flex" gap={1} flexWrap="wrap">
          {stepTwoState.selectedDate === '' ? (
            <Box
              style={{
                display: 'inline-flex',
                justifyContent: 'center',
                width: '211px',
                borderRadius: '88px',
                backgroundColor: '#F7F7F8',
                padding: '14px 16px',
              }}
            >
              <Typography variant={'subtitle1SmallR'} color={'#313D53'} textAlign={'center'} component="p">
                Επίλεξε πρώτα ημερομηνία
              </Typography>
            </Box>
          ) : (
            getTimes
          )}
        </Box>
      </Box>
      <MissingClickModal isOpen={missingClickModal} setOpen={setMissingClickModal} />
    </>
  );
};

export default StepTwoContent;
