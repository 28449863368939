import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, tabsClasses } from '@mui/material';
import { theme } from 'components';
import useMediaQuery from '@mui/material/useMediaQuery';
import HeroCard from './HeroCard';
import HeroPromoCard from './HeroPromoCard';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import blank from '../../assets/png/imagePromoBlank.png';
import { usePromosQuery } from '../../redux/api/queries/promosQueries';
import { Promo } from '../../models/Promo';
import { useIonRouter } from '@ionic/react';
import { Link } from 'react-router-dom';
import HeroSectionCarousel from './HeroSectionCarousel';
import { Browser } from '@capacitor/browser';
import { isWeb } from '../../utils/device';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const HeroSectionPost = () => {
  const router = useIonRouter();

  const isCapacitorApp = !isWeb();

  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const extralgUp = useMediaQuery(theme.breakpoints.up('extraLg'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'));

  const {
    data: promos,
    isLoading: loadPromo,
    isFetching: fetchPromo,
  } = usePromosQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });

  const [value, setValue] = useState<number>(0);
  const [images, setImages] = useState<string[]>([]);
  const [colors, setColors] = useState<(string | undefined)[]>([]);
  const [urls, setUrls] = useState<string[]>([]);
  const [carouselLoading, setCarouselLoading] = useState<boolean>(true);

  useEffect(() => {
    if (promos) {
      const newImages = promos.data.map((promo: Promo) => (promo.image ? promo.image : blank));
      const newColors = promos.data.map((promo: Promo) => promo.color);
      const newUrls = promos.data.map((promo: Promo) => {
        return promo.url;
      });

      setImages(newImages);
      setColors(newColors);
      setUrls(newUrls);
    }
  }, [promos]);

  useEffect(() => {
    setCarouselLoading(fetchPromo);
  }, [fetchPromo]);

  useEffect(() => {
    if (carouselLoading && promos?.totalCount) {
      setTimeout(function () {
        setCarouselLoading(false);
      }, 2500);
    }
  }, [carouselLoading]);

  const handlePromoClick = (promo: any) => {
    if (promo?.url.includes('/blog/')) {
      if (isCapacitorApp) {
        Browser.open({ url: 'https://www.pigogo.gr' + promo?.url || '' });
      } else {
        window.open('https://www.pigogo.gr' + promo?.url);
      }
      return;
    }

    router.push(promo?.url);
  };

  const getMobile = () => {
    return (
      <>
        {carouselLoading && (
          <Box height={'304px'} py={5} px={3} display="flex" alignItems="center" justifyContent="center">
            <HeroPromoCard loading />
          </Box>
        )}
        {!carouselLoading &&
          promos &&
          promos.totalCount <= 1 &&
          promos.data.map((promo, key) => (
            <Link
              to={urls[value]}
              key={key}
              style={{ textDecoration: 'none' }}
              onClick={(e: any) => {
                e.preventDefault();
                handlePromoClick(promo);
              }}
            >
              <Box height={'auto'} sx={{ maxHeight: '608px', width: '100%', minHeight: '344px' }}>
                <Box
                  style={{
                    background: colors[value]
                      ? 'linear-gradient(0deg, ' + colors[value] + ', ' + colors[value] + ')'
                      : 'linear-gradient(0deg, rgba(49, 61, 83, 0.8), rgba(49, 61, 83, 0.8)), linear-gradient(98.72deg, rgba(16, 31, 75, 0.4) 0%, rgba(233, 104, 138, 0.4) 100%)',
                    width: '100%',
                    height: ' 100%',
                    position: 'absolute',
                    inset: '0',
                  }}
                >
                  <img
                    style={{
                      top: 0,
                      left: 0,
                      opacity: 0.2,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    alt={'hero-section'}
                    src={promo.image ?? blank}
                  />
                </Box>
                <Box
                  sx={{
                    py: 5,
                    px: 3,
                    top: '0',
                    zIndex: 1400,
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                  }}
                >
                  <Box key={key} display="flex" alignItems="center" justifyContent="flex-start">
                    <HeroPromoCard
                      title={promo.title}
                      subtitle={promo.subtitle}
                      button={promo.call_to_action}
                      //handle={() => handlePromoClick(promo)}
                    />
                  </Box>
                </Box>
              </Box>
            </Link>
          ))}
        {!carouselLoading && promos && promos.totalCount > 1 && (
          <HeroSectionCarousel setCarouselLoading={setCarouselLoading}>
            {promos.data.map((promo, key) => (
              <Link
                to={promo.url}
                key={key}
                onClick={(e: any) => {
                  e.preventDefault();
                  handlePromoClick(promo);
                }}
              >
                <Box height={'344px'} sx={{ maxHeight: '608px', width: '100%', minHeight: '344px' }}>
                  <Box
                    style={{
                      background: colors[value]
                        ? 'linear-gradient(0deg, ' + colors[value] + ', ' + colors[value] + ')'
                        : 'linear-gradient(0deg, rgba(49, 61, 83, 1), rgba(49, 61, 83, 1)), linear-gradient(98.72deg, rgba(16, 31, 75, 1) 0%, rgba(233, 104, 138, 1) 100%)',
                      width: '100%',
                      height: ' 100%',
                      position: 'relative',
                    }}
                  >
                    <img
                      style={{
                        top: 0,
                        left: 0,
                        opacity: 0.2,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                      alt={'hero-section'}
                      src={promo.image ?? blank}
                    />
                  </Box>
                  <Box
                    sx={{
                      py: 5,
                      px: 3,
                      top: '0',
                      zIndex: 1400,
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      boxSizing: 'border-box',
                    }}
                  >
                    <Box key={key} display="flex" alignItems="center" justifyContent="flex-start">
                      <HeroPromoCard
                        title={promo.title}
                        subtitle={promo.subtitle}
                        button={promo.call_to_action}
                        //handle={() => handlePromoClick(promo)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))}
          </HeroSectionCarousel>
        )}
      </>
    );
  };

  const getDesktop = () => {
    return (
      <Box className={carouselLoading ? 'hero--loading' : ''} height={'500px'} width={'100%'} id={'promo_box'}>
        {!carouselLoading && promos && promos?.totalCount > 1 && (
          <HeroSectionCarousel desktop={true} setCarouselLoading={setCarouselLoading}>
            {promos?.data?.map((promo, key) => {
              return (
                <Box height={'500px'} sx={{ width: '100%' }} key={key}>
                  <Link
                    to={promo.url}
                    key={key}
                    onClick={(e: any) => {
                      e.preventDefault();
                      handlePromoClick(promo);
                    }}
                  >
                    <Box
                      style={{
                        background: colors[key]
                          ? 'linear-gradient(0deg, ' + colors[key] + ', ' + colors[key] + ')'
                          : 'linear-gradient(0deg, rgba(49, 61, 83, 1), rgba(49, 61, 83, 1)), linear-gradient(98.72deg, rgba(16, 31, 75, 1) 0%, rgba(233, 104, 138, 1) 100%)',
                        width: '100%',
                        height: '500px',
                        position: 'relative',
                      }}
                    >
                      <img
                        style={{
                          top: 0,
                          left: 0,
                          opacity: 0.2,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        alt={'hero-section'}
                        src={promo.image ?? blank}
                      />
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      sx={{
                        py: 5,
                        px: 3,
                        top: '0',
                        zIndex: 1400,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        boxSizing: 'border-box',
                      }}
                    >
                      <Box
                        sx={{
                          ' & .heroPromoCard': {
                            width: lgUp ? '524px' : '100%',
                          },
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        alignSelf="flex-end"
                        mr={lgUp ? 13.5 : 3}
                        ml={lgUp ? 0 : 7}
                      >
                        <HeroPromoCard
                          title={promo.title}
                          subtitle={promo.subtitle}
                          button={promo.call_to_action}
                          //handle={() => handlePromoClick(promo)}
                        />
                      </Box>
                    </Box>
                  </Link>
                </Box>
              );
            })}
          </HeroSectionCarousel>
        )}
        {!carouselLoading && promos && promos?.totalCount <= 1 && (
          <Box height={'500px'} sx={{ width: '100%' }} key={0}>
            <Link
              to={urls[0]}
              onClick={(e: any) => {
                e.preventDefault();
                handlePromoClick(promos.data[0]);
              }}
            >
              <Box
                style={{
                  background: colors[0]
                    ? 'linear-gradient(0deg, ' + colors[0] + ', ' + colors[0] + ')'
                    : 'linear-gradient(0deg, rgba(49, 61, 83, 1), rgba(49, 61, 83, 1)), linear-gradient(98.72deg, rgba(16, 31, 75, 1) 0%, rgba(233, 104, 138, 1) 100%)',
                  width: '100%',
                  height: '500px',
                  position: 'relative',
                }}
              >
                <img
                  style={{
                    top: 0,
                    left: 0,
                    opacity: 0.2,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                  alt={'hero-section'}
                  src={promos.data[0].image ?? blank}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                  py: 5,
                  px: 3,
                  top: '0',
                  zIndex: 1400,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  boxSizing: 'border-box',
                }}
              >
                <Box
                  sx={{
                    ' & .heroPromoCard': {
                      width: lgUp ? '524px' : '100%',
                    },
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  alignSelf="flex-end"
                  mr={lgUp ? 13.5 : 3}
                  ml={lgUp ? 0 : 7}
                >
                  <HeroPromoCard
                    title={promos.data[0].title}
                    subtitle={promos.data[0].subtitle}
                    button={promos.data[0].call_to_action}
                    //handle={() => handlePromoClick(promo)}
                  />
                </Box>
              </Box>
            </Link>
          </Box>
        )}
        {carouselLoading && (
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="column"
            alignSelf="flex-end"
            mr={lgUp ? 13.5 : 3}
            ml={lgUp ? 0 : 7}
            sx={{
              width: '100%',
              height: '500px',
            }}
          >
            <HeroPromoCard loading />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box display={'flex'} flexDirection={mdDown ? 'column' : 'row'} maxHeight={mdDown ? 'inherit' : '500px'}>
      <Box
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        display={!mdDown ? 'flex' : undefined}
        sx={{ position: 'relative', zIndex: 1000 }}
        width={mdDown ? '100%' : xlUp ? '55%' : lgDown ? 'calc(100% - 464px)' : 'calc(100% - 579px)'}
        maxWidth={extralgUp ? '55vw' : 'inherit'}
      >
        {mdDown ? getMobile() : getDesktop()}
      </Box>
      <Box
        boxSizing={'border-box'}
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ backgroundColor: '#FCE5D8' }}
        maxWidth={lgDown && !mdDown ? '400px' : 'inherit'}
        width={mdDown ? '100%' : lgUp ? '579px' : '464px'}
        minWidth={mdDown ? '100%' : lgUp ? '579px' : '464px'}
        padding={mdDown ? '40px 16px' : '0 32px'}
      >
        <HeroCard />
      </Box>
    </Box>
  );
};

export default HeroSectionPost;
